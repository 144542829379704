
export function generateCodeSystem(code) {
    const timestamp = Date.now().toString(36);
    const random = Math.random().toString(36).substr(2, 4); // Adjust length here
    
    const uniqueId = code + '-' + timestamp + '-' + random;
    
    // Ensure length is at least 6 characters
    if (uniqueId.length < 6) {
        const padding = '000000'.substr(0, 6 - uniqueId.length);
        return uniqueId + padding;
    }
    
    return uniqueId;
}
