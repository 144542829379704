<template>
  <div>
    <div class="mt-3">
      <v-btn dark color="#172b23" block small tile @click="showBotDialog('month')">{{ currentMonth }} {{currentYear == todayYear ? '' : currentYear}}</v-btn>
    </div>
    <div
      class="calendar"
      :class="{ 'swipe-left': isSwipeLeft, 'swipe-right': isSwipeRight }"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      ref="calendarContainer"
    >
      <div class="days-of-week">
        <span v-for="day in daysOfWeek" :key="day" class="day" @click="handleWeekDayClick(index,currentMonth, currentYear)">{{ day }}</span>
        <!-- when this is click it should trigger hadDateclick with a number date -->
      </div>
      <div class="week">
        <span
            v-for="(day, index) in currentWeek"
            :key="index"
            :class="{ 
                today: isToday(day, currentMonth, currentYear), 
                chosenDate: isChosenDate(day, currentMonth, currentYear), 
                clickable: !isSwiping,
                pastDate: isPastDate(day, currentMonth, currentYear)
            }"
            class="day"
            @click="handleDateClick(day, currentMonth, currentYear)"
        >
            {{ day }}
        </span>
        </div>
    </div>
    <v-bottom-sheet v-if="showDateDialog" v-model="showDateDialog"  >
            <v-date-picker  v-if="dateType == 'month'" v-model="datePicker" type="month" @change="handleChangeMonth(month)" :min="minAllowedDate"></v-date-picker>
            <v-date-picker v-else v-model="datePicker"  ></v-date-picker>
        </v-bottom-sheet>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      currentMonth: '',
      currentYear: '',
      currentWeek: [],
      daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      currentDate: moment(),
      todayDate:  moment(),
      todayYear:moment().format('YYYY'),
      numWeeksToShow: 5,
      touchStartX: null,
      touchEndX: null,
      isSwipeLeft: false,
      isSwipeRight: false,
      isSwiping: false, 
      showDateDialog: false,
      dateType: null,
      datePicker: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    minAllowedDate() {
      return moment().format('YYYY-MM');
    }
  },
  mounted() {
    this.updateCalendar();
  },
  methods: {
    isChosenDate(day, month, year) {
     
        if (this.currentDate.format('YYYY') == year && this.currentDate.format('D') == day && this.currentDate.format('MMMM') == month ) {
            return true
        }else{
            return false
        }
        // console.log("yayayya",(moment().year(year).month(month - 1).date(day).isSame(this.currentDate, 'day')))
        // return moment().year(year).month(month - 1).date(day).isSame(this.currentDate, 'day');
    },
    handleChangeMonth(val){
      this.dataType = val
        if (this.dateType == 'month') {

            var chosenDate = moment(this.datePicker).startOf('month');

            if (chosenDate.format('YYYY-MM') === moment().format('YYYY-MM')) {
            this.currentDate = moment();
            } else {
            this.currentDate = chosenDate;
            }
        }else{

        }
        this.updateCalendar()
    },
    handleWeekDayClick(index, month, year) {
      const clickedDate = this.currentWeek[index];
      this.handleDateClick(clickedDate,month, year);
    },
    showBotDialog(type) {
      this.showDateDialog = true;
      this.dateType = type;
    },
    updateCalendar() {
      this.currentMonth = this.currentDate.format('MMMM');
      this.currentYear = this.currentDate.format('YYYY');
      const startOfWeek = this.currentDate.clone().startOf('week');
      this.currentWeek = Array.from({ length: 7 }, (_, i) => startOfWeek.clone().add(i, 'days').date());
      this.showDateDialog = false
      if (this.dateType == 'month') {
        this.$emit('dateChange', this.currentDate.format('YYYY-MM-01'))
      }else{
        this.$emit('dateChange', this.currentDate.format('YYYY-MM-DD'))
      }
      this.dataType = null
    },
    nextWeek() {
      this.currentDate.add(1, 'week').startOf('week');
      this.updateCalendar();
    },
    previousWeek() {
      if (!moment().isSame(this.currentDate, 'week')) {
        this.currentDate.subtract(1, 'week').startOf('week');
        this.updateCalendar();
      }
    },
    isToday(day, currentMonth, currentYear) {
        const today = moment();
        const year = today.year(); 
        if (currentYear != today.year()) {
            return false
        }
        const monthNumeric = moment().month(currentMonth).format('M');
        
        const targetDate = moment().year(year).month(monthNumeric - 1).date(day);

        return today.isSame(targetDate, 'day') && today.isSame(targetDate, 'month') && today.isSame(targetDate, 'year');
    },
    handleTouchStart(event) {
      //detect if the tocuh move or not
      this.touchStartX = event.touches[0].clientX;
      this.isSwiping = true;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
      if (this.touchStartX - this.touchEndX > 50) {
        this.isSwipeLeft = true;
        this.isSwipeRight = false;
      } else if (this.touchEndX - this.touchStartX > 50) {
        this.isSwipeRight = true;
        this.isSwipeLeft = false;
      } else {
        this.isSwiping = false;
        this.isSwipeLeft = false;
        this.isSwipeRight = false;
      }
    },
    handleTouchEnd() {
        if (this.touchEndX) {
            if (this.touchStartX - this.touchEndX > 50) {
                this.nextWeek();
            } else if (this.touchEndX - this.touchStartX > 50) {
                this.previousWeek();
            }
            this.resetSwipeStates();
        }
      
     
    },
    handleDateTouchStart(event) {
      // Prevent swipe logic when starting to touch a date
      this.isSwiping = false;
    },
    handleDateTouchMove(event) {
      // Prevent swipe logic when moving over a date
      this.isSwiping = false;
    },
    handleDateTouchEnd() {
      // Prevent swipe logic when ending touch on a date
      this.isSwiping = false;
    },
    isPastDate(day, month, year) {
      const monthNumeric = moment().month(month).format('M');
      const targetDate = moment().year(year).month(monthNumeric - 1).date(day);
      return targetDate.isBefore(moment(), 'day');
  },
    handleDateClick(day,month,year) {
      const monthNumeric = moment().month(month).format('M');
      const clickedDate = moment().year(year).month(monthNumeric - 1).date(day);

      // Check if the clicked date is in the past
      if (clickedDate.isBefore(moment(), 'day')) {
          // Do nothing if the date is in the past
          return;
      }

      const date = clickedDate.format('YYYY-MM-DD');
      this.currentDate = clickedDate;
      this.$emit('dateChange', date);
      console.log('Clicked on:', date);
    },
    resetSwipeStates() {
      this.isSwipeLeft = false;
      this.isSwipeRight = false;
      this.isSwiping = false;
      this.touchStartX = null;
      this.touchEndX = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  color: white;
  text-align: center;
  padding: 10px;
}

.day {
  color: white;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.5s ease;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
}

.swipe-left {
  transform: translateX(-100%);
}

.swipe-right {
  transform: translateX(100%);
}

.days-of-week {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  .day{
    font-size:11px;
    font-weight:bold;
  }
}

.week {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  .day{
    font-size:18px;
  }
}

.today {
  background-color: #ff8800;
}

.chosenDate{
    background-color: #BF360C;

}

.clickable {
  cursor: pointer;
}
.pastDate {
  color: #aaa; /* Gray out the text */
  pointer-events: none; /* Disable pointer events */
}
</style>
