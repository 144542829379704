<template>
    <div>
        <div class="searchQuad">
            <h2>LIBRARY</h2>
            <div>
                <v-text-field style="margin-bottom: 10px;" v-model="query" outlined label="Search your library" hide-details dense clearable solo append-icon="mdi-magnify"></v-text-field>
                <v-btn @click="playlistModal =true" class="create-btn" small>Add Playlist</v-btn>
            </div>
        </div>
      
        <div class="listHolder">
            <div class="content" @click="goToPlaylist('liked')">
                <div>
                    <img style="width: 70px;" src="https://d2hyqud1qblstw.cloudfront.net/dawataTemporary/system/default/dawata.jpg" alt="Image 1" class="">
                </div>
                <div>
                    <h3>Liked Videos</h3>
                    <!-- <p>{{ pl.count }} {{ pl.count > 1 ? 'Videos' :' Video' }}</p> -->
                </div>
            </div>
            <div class="content" v-for="(pl, index) in playlists" :key="index" @click="goToPlaylist(pl.id)">
                
                <div v-if="pl.count == 0" >
                    <img style="width: 70px;" src="https://d2hyqud1qblstw.cloudfront.net/dawataTemporary/system/default/dawata.jpg" alt="Image 1" class="">
                </div>
                <div v-else>
                    <img style="width: 70px;" src="https://d2hyqud1qblstw.cloudfront.net/dawataTemporary/system/default/dawata.jpg" alt="Image 1" class="">
                </div>
                <div>
                    <h3>{{ pl.name }}</h3>
                    <p>{{ pl.count }} {{ pl.count > 1 ? 'Videos' :' Video' }}</p>
                </div>
            </div>
        </div>
        <v-dialog  v-model="playlistModal" max-width="400">
            <v-card>
                <v-card-title class="text-h6"> Add to Playlist <a @click="playlistModel = false"><v-icon>mdi-close-box</v-icon></a></v-card-title>

                <div style="padding: 20px;">
                    <v-text-field v-model="playlist_name" clearable dense solo></v-text-field>
                </div>
              
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="CreatePlaylist()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DawataPage',

    data() {
        return {
            loading:true,
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            playlists:[],
            query:null,
            playlistModal:false,
            playlist_name:null

        };
    },

    mounted() {
        
        this.getPlaylist()
    },
    watch: {
        query(newValue) {
            // Make your API request here using the newValue
            // For example, use Axios to make an API call to your backend
            this.loading = true
            console.log("mores");
            this.queryPlaylist(newValue);
        },
        
    },

    methods: {
        goToPlaylist(id){
            console.log("mokokkkko",id);
            this.$router.push(`/playlist/${id}`);
        },
        queryPlaylist(v){
            this.req('p_videos', 'query_playlist', {
                query: v,
                user_id:this.$store.state.user_id
            }).then((data) => {
                console.log("pud omo", data)
                this.playlists = data
                this.loading = false

                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
        },
        CreatePlaylist(){
            this.loading = true

            console.log("loggeed",this.playlist_name)
            this.nodeReq('playlist/create_playlist', {
                name: this.playlist_name,
                user_id: this.$store.state.user_id
                }).then( (data) => {
                    this.playlistModal = false
                    this.getPlaylist()

                }).catch((error) => {
                });


        },
        
        getPlaylist(){
            this.nodeReq('playlist/get_playlist', {user_id: this.$store.state.user_id}).then((data) => {
                this.playlists = data
                this.loading = false
                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
            // this.req('p_videos', 'get_playlist', {user_id: this.$store.state.user_id}).then((data) => {
            //     this.playlists = data
            //     this.loading = false
            //     // API call is successful (optional: you can handle success if needed)
            // }).catch((error) => {
            //     // API call failed (optional: you can handle errors if needed)
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading{
    padding: 20px;
   
}
.searchQuad{
    padding: 20px 20px 0px;
    h2{
        color:#fff
    }
}
.listHolder{
    padding: 20px;
    max-height: 65vh;
    overflow: auto;
    .content{
        color:#fff;
        gap: 10px;
        display: flex;
        color: #fff;
        align-items: center;
        margin-bottom: 20px;
        .square-container {
            position: relative;
            width: 100px; /* Set the width of the square container */
            height: 100px; /* Set the height of the square container */
            display: grid;
            grid-template-columns: repeat(2, 50%); /* Create two columns */
            grid-template-rows: repeat(2, 50%); /* Create two rows */
        }

        .square-container-single {
            position: relative;
            width: 100px; /* Set the width of the square container */
            height: 80px; /* Set the height of the square container */
            display: grid;
            .square-image-single {
                width: 100%; /* Make the image fill its container */
                height: 100%; /* Make the image fill its container */
                object-fit: cover; /* Scale and crop the image to fill the container */
            }
                
        }

       

        .square-image {
            width: 100%; /* Make the image fill its container */
            height: 100%; /* Make the image fill its container */
            object-fit: cover; /* Scale and crop the image to fill the container */
        }
    }

}


</style>