<template>
    <v-container fluid fill-height dark>
        <v-row
            align="center"
            justify="center"
            class="text-center"
        >
            <v-col>
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="#fff"
                ></v-progress-circular>
                <div style="color:#fff;" v-else>
                    <p>We have a new update! 🎉</p>
                    <p v-if="isIOS">Please open your App Store to update the app.</p>
                    <p v-else-if="isAndroid">Please open your Play Store to update the app.</p>
                    <p v-else>Update available! Please check your device's app store.</p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: true, // initially true to show the loading spinner
            isIOS: false,
            isAndroid: false,
        };
    },
    mounted() {
        this.checkDevice();
    },
    methods: {
        checkDevice() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Simulate a delay to show the loading spinner
            setTimeout(() => {
                // Check for iOS devices
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    this.isIOS = true;
                } 
                // Check for Android devices
                else if (/android/i.test(userAgent)) {
                    this.isAndroid = true;
                }
                this.loading = false;
            }, 2000); // 2-second delay to simulate loading
        }
    }
}
</script>

<style lang="scss" scoped>
.v-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
