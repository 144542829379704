<template>
    <div class="no-video">
        <v-icon class="icon-n" color="#fff">mdi-video-image</v-icon>
        <h3>NO VIDEOS FOUND</h3>
    </div>
</template>

<script>
export default {
    name: 'DawataNoVideos',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.no-video{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    gap: 20px;
    .icon-n{
        font-size: 95px;
    }
}
</style>