<template>
    <div class="category-holder">
        <h3 style="color:#fff; margin-bottom:15px;  font-family: 'Andada Pro', serif;">Discover</h3>
        <swiper-container autoplay="true" class="mySwiper" pagination="true" effect="coverflow" grab-cursor="true" :loop="true" centered-slides="true"
            slides-per-view="auto" coverflow-effect-rotate="50" coverflow-effect-stretch="0" coverflow-effect-depth="100"
            coverflow-effect-modifier="1" coverflow-effect-slide-shadows="true">
            <swiper-slide>
                <img  :src="'https://d2hyqud1qblstw.cloudfront.net/ads/1/1.webp'" class="img-fluid" blank="true">
            </swiper-slide>
            <swiper-slide>
                <img  :src="'https://d2hyqud1qblstw.cloudfront.net/ads/1/2.webp'" class="img-fluid" blank="true">
            </swiper-slide>
            <swiper-slide>
                <img  :src="'https://d2hyqud1qblstw.cloudfront.net/ads/1/3.webp'" class="img-fluid" blank="true">
            </swiper-slide>
        </swiper-container>
       <h3 class="mt-4" style="color:#fff; margin-bottom:15px;  font-family: 'Andada Pro', serif;">Keep Moving</h3>
     
       <v-lazy class="category-container"  :min-height="'30vh'" :options="{'threshold':0.5}" transition="fade-transition" >
        <div class="content">
            <div v-for="(cat, index) in categories" :key="index" class="category-col" @click="changeView(cat.name, cat.id)">
            <v-card class="mx-auto my-2" style="border-radius: 20px;">
                <v-img
                :src="cat.image"
                :aspect-ratio="16/9"
                class="white--text align-end category-item"
                cover
                style="border-radius: 20px; height: 140px;"
                >
                <v-card-title class="category-style">{{ cat.name.toUpperCase() }}</v-card-title>
                </v-img>
            </v-card>
            </div>
        </div>
        </v-lazy>

        <h3 class="mt-4" style="color:#fff; margin-bottom:15px;  font-family: 'Andada Pro', serif;">Featured Guru</h3>
        <swiper-container class="mySwiper" autoplay="true" :slides-per-view="3" :loop="true" pagination="false" effect="coverflow" grab-cursor="true" centered-slides="true"
           >
            <swiper-slide v-for="(item, n) in instructorFeatured" :key="'index'+n" class="text-center" >
                <a href="javascript:void()" @click="gotoInstructor(item.instructor_id)">
                    <v-avatar  circle :size="90">
                        <img  :src="item.avatar"  blank="true">
                    </v-avatar>
                    <h5 class="mt-2" style="color:#fff">{{ item.name }}</h5>
                </a>
                
            </swiper-slide>
        </swiper-container>
      <!-- <VersionChecker/> -->
    </div>
</template>

<script>
import ListOfVideos from '@/components/ListOfVids.vue'
import SingleVideo from '@/components/singleViewVideo.vue'
import { mapActions } from 'vuex';
import VersionChecker from  '@/components/versionChecker'


export default {
    name: 'Home', 
    components:{
        ListOfVideos,
        SingleVideo,
        VersionChecker
    },
    data() {
        return {
            viewType:'',
            realView:'',
            defaultCategories:[
                {
                    name:"Instructors",
                    id:998,
                    image:'https://d2hyqud1qblstw.cloudfront.net/system/instructor_img.webp',
                    count:1
                },
                {
                    name:"Live Classes",
                    id:999,
                    image:'https://d2hyqud1qblstw.cloudfront.net/system/live_img.webp',
                    count:1
                },
                {
                    name:"Videos",
                    id:999,
                    image:'https://d2hyqud1qblstw.cloudfront.net/system/live_img.webp',
                    count:1
                },
               
            ],
            categories:[],
            videos:null,
            isloading:true,
            instructorFeatured:[],
        };
    },
    watch: {
        viewType(newValue, oldValue) {
            this.realView = newValue
        }
    },

    async created(){
        await this.getCategoriesMain()
        this.preloadImages()
    },

    mounted() {
        this.viewType = 'home'
        this.getFeat()
        // this.requestUpdateCategory();
       
    },
    activated() {
        // Logic to run when component is activated
        this.viewType = 'home';
        this.getFeat();
        this.preloadImages();
        console.log('Home component activated');

    },
    deactivated() {
        // Logic to run when component is deactivated
        console.log('Home component deactivated');

    },

    methods: {
        getImageUrl (imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`
    },
    onSwiper (swiper) {
      console.log(swiper)
    },
    onSlideChange () {
      console.log('slide change')
    },
    
        async getFeat(){
            const data = await this.nodeReq('instructor/get_featured/',{})
            this.instructorFeatured = data
        },
        async requestUpdateCategory(){
            const data = await this.nodeReq('category/getCategory/')
            this.$store.dispatch('actionCategoryUpdate', this.defaultCategories.concat(data));
            this.renderCategories()
        },
         renderCategories(){
            this.isloading = true;
            this.categories = this.$store.state.category
             this.$nextTick(() => {
                    this.isloading = false;
                });
        },

        preloadImages() {
            this.categories.forEach(cat => {
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = cat.image;
                link.as = 'image';
                document.head.appendChild(link);
            });
        },

        changeView(view,id){
            var cat = view.replace(/\s+/g, '_').toLowerCase()
            console.log("wakokeorkso",cat)
            if(cat == "instructors"){
                this.$router.push({name:'Instructor'})
            }else if(cat == "live_classes" ){
                this.$router.push(`/live/`);
            }else{
                this.$router.push(`/vids/${cat}/${id}`);
            }
        },

        gotoInstructor(id){
            this.$router.push(`/instructor/${id}`);

        },
       
        async getCategoriesMain(){
            this.$bus.$emit('change_loading', true);
                      this.isloading = true

            try {
                const data = await this.nodeReq('category/getCategory/')

           
                console.log("cat m,ain", data);
                this.categories = this.defaultCategories; // Concatenating the defaultCategories with the new data

               
                this.$nextTick(() => {
                    this.isloading = false;
                });
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        },
       
        
    },
};
</script>

<style lang="scss" scoped>
.category-holder{
    overflow-x: hidden;
    padding: 20px;

    .card-loader{
        background: #232323de;
        height: 150px;
        margin-bottom: 15px;
    }

    .category-container{
        min-height: 79vh;
        max-height: 79vh;
        overflow-x: hidden;
        overflow-y: auto;

        .category-item{
          
        }
    }
    .category-style{
        position: absolute;
        top: 0;
        left: 0;
        padding: 0px 20px;
        background: #eaeaeae9;
        font-size: 13px;
        display: flex;
        justify-content: center;
        font-family: 'Andada Pro', serif;
        color: #84593f;
        font-weight: bold;
        width: 100%;

    }

    @media (min-width: 1200px) {
        /* CSS rules for extra large devices */
    }
    .ads {
        // width: 240px!important;
        height: 160px;
    }
    .img-fluid {
        width: 100%!important;
        height: 180px;
        // object-fit: fit;
    }
   
    .ml-auto, .mx-auto {
    margin-left: auto;
    }
    .mr-auto, .mx-auto {
    margin-right: auto;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 16px; /* Adjust as needed */
    }

    .category-col {
        width: calc(50% - 8px); /* 50% width with gap between columns */
    }

    /* Media query for smaller screens */
    /* Media query for tablet screens */
    @media screen and (min-width: 768px) and (max-width: 1023px) {
    .category-col {
        width: calc(32.333% - 8px); /* 33.333% width with gap between columns on tablets */
    }
    }

    /* Media query for smaller screens */
    @media screen and (max-width: 767px) {
    .category-col {
        width: calc(50% - 8px); /* 50% width with gap between columns on mobile */
    }
    }
    }
</style>