<template>
    <div class="single-view">
        <div class="header">
            <v-overlay  :absolute="true"></v-overlay>
            <video muted autoplay loop width="100%" height="160px" :src="instructor.avatar_timeline " playsinline></video>
           <div class="avatar-holder">
                <h3>{{instructor.name || "INSTRUCTOR NAME"}}</h3>
                <v-avatar class="avatar-img" size="120">
                    <img :src="instructor.avatar || 'https://dawata.s3.ap-southeast-2.amazonaws.com/system/default_playlist.png'"  alt="John">
                </v-avatar>
           </div>
        </div>
      
        <div class="bio">
            <div class="book-button">
                <v-btn v-if="instructor.schedule_count != 0" @click="bookAClass" class="btn-booking" color="#066b43" elevation="2" outlined >Book 1-on-1 Session</v-btn>           
             </div>
             <!-- <div :class="{ 'show-more': showMore }">
                <p ref="descriptionText" :class="{ 'show-less': showLess }">{{ instructor.bio }}</p>
                <button v-if="showMore" @click="toggleShowLess">
                    {{ showLess ? 'Show More' : 'Show Less' }}
                </button>
            </div> -->
            <p v-if="!showFullBio" ref="descriptionText">{{ truncatedBio }}</p>
            <p v-else ref="descriptionText">{{ instructor.bio }}</p>
            <v-btn @click="toggleBio" outlined small color="#fff">{{ showFullBio ? 'Show Less' : 'Show More' }}</v-btn>           
           </div>
           <v-divider color="#fff"></v-divider>
        <div class="sec-header"> 
            <h5>Showing {{ vid_count }} {{ vid_count >1 ?'Videos' :'Video'}}</h5>
            <v-btn @click="openThyFilter()"  color="#fff"  elevation="2"  outlined  x-small>FILTER</v-btn>
        </div>
        <div class="third-header"> 
            <div class="input-holder">
                <!-- <h5>Sort By: </h5>  
                <v-select class="j-select" :items="sorts" v-model="sortedVid" label="Sort" :height="20" solo dense hide-details></v-select> -->
            </div>
            <div class="btn-actions">
                <v-btn   color="#fff" icon elevation="2"    x-small><v-icon>mdi-grid-large</v-icon></v-btn>
                <v-btn   color="#fff" icon elevation="2"    x-small><v-icon>mdi-menu</v-icon></v-btn>
            </div>
        </div>
        <div class="list-container">
            <ListOfVideos :videos="videos" @selectVid="selectVideo"/>
        </div>

        <v-bottom-sheet v-model="requestDialog" v-if="requestDialog" fullscreen persistent>
            <v-sheet class="text-center pa-2" height="100%" style="overflow: auto;position: relative; font-family: 'Andada Pro', serif;">
                <div class="small-details" style="width: 100%; height: 120px;  text-align: left;">
                    <div class="">
                        <h3>Book A Private Class <v-chip v-if="isGodMode" color="warning">Test Mode</v-chip></h3>
                    </div>
                    <v-divider></v-divider>
                   <div style="display: flex; justify-content: space-between; padding: 10px; width: 100%;">
                    <a v-if="step != 1" @click.prevent="step--" style="color:#000; text-decoration: none" href="javascript::void(0)"> <v-icon color="#000">mdi-backburger</v-icon>Back</a>
                    <a v-if="step == 1" @click.prevent="closeBook" style="color:#000; text-decoration: none" href="javascript::void(0)"> <v-icon color="#000">mdi-backburger</v-icon>Cancel</a>

                   </div>
                </div>
                <div v-if="step==1" class="pa-3">
                    <h4>This Class Duration is</h4>
                    <h2>{{ schedule.duration }} mins</h2>
                    <h4 class="my-3">What is your preferred date?</h4>
                    <v-date-picker class="mt-4"  v-model="choosenDate" :min="today" :max="max" show-current :allowed-dates="allowedDates" fullWidth></v-date-picker>
                    <v-btn style="position: absolute; left:0px; bottom: 0px;" color="success" block @click="next(1, 2)">Continue</v-btn>
                </div>
                <div  v-if="step==2">
                    <h4 class="my-3">What is your preferred time?</h4>
                    <v-select dense :items="timeConst" item-text="label" item-value="value" v-model="timeStart" @change="isClassDisabled" label="What time you want to start" solo hide-details></v-select>
                    <h4 class="my-3">Choose a category?</h4>
                    <v-select dense  :items="this.schedule.practices" item-text="name" item-value="value" return-object v-model="selectPractice" solo hide-details @change="practiceChoseEvent"></v-select>
                    <h4 class="my-3">Choose a type?</h4>
                    <!-- {{ this.subCats }} -->
                    <v-select @change="subCatEvent" dense  :items="this.subCats" item-text="name" return-object v-model="selectSubCat" solo hide-details></v-select>
                    <div style="padding: 10px;">
                        Booking Details<br>
                        <p>{{ schedule.name }}</p>
                        <p>{{ schedule.duration }} mins</p>
                        <p>{{ choosenDate }} - {{ timeto12(timeStart, true) == 'Invalid date' ? 'N/A' : timeto12(timeStart, true)}} till {{ timeto12(timeStart) == 'Invalid date' ? 'N/A' : timeto12(timeStart) }}</p>
                        <p>{{ selectPractice.name || 'N/A' }} - {{ selectSubCat.name || 'N/A' }}</p>
                        <p>{{ sub_description }}</p>
                    </div>
                    <v-btn :loading="loadbtn"  :disabled="isClassDisabled()" style="position: absolute; left:0px; bottom: 0px; height:90px;font-size: 25px;" color="success" block @click="payClass()">
                        Pay {{ total }} PHP
                        <template v-slot:loader>
                            <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                            </span>
                            Please Wait

                        </template>
                    </v-btn>
                    <!-- <v-btn :disabled="isClassDisabled()" style="position: absolute; left:0px; bottom: 0px; height:90px;font-size: 25px;" color="success" block @click="payClass()">Pay {{ total }} PHP</v-btn> -->
                </div>
    
            </v-sheet>
        </v-bottom-sheet>
        
    </div>
</template>

<script>
import ListOfVideos from '@/components/ListOfVids.vue'
import moment from 'moment';

export default {
    name: 'DawataPageView',
    components:{
        ListOfVideos,
    },
    data() {
        return {
            maxBioLength: 130,
            showFullBio: false,
            loadbtn:false,
            isGodMode:false,
            step:1,
            timeStart:null,
            requestDialog:false,
            showMore: false,
            showLess: true,
            videos:null,
            vid_count:0,
            choosenDate:moment().format('YYYY-MM-DD'),
            sorts:["Newest","Oldest"],
            sortedVid:"Newest",
            instructor:[],
            today:moment().format('YYYY-MM-DD'),
            schedule:[],
            min:null,
            max:null,
            excludeThisDays:[],
            subCats:[],
            selectSubCat:0,
            selectPractice:[],
            total:0,
            payDisable:false,
            originalFilter:[
                {
                name:"Main Category",
                options:[]
                },
                {
                name:"Type",
                options:[]
                },
              
                {
                name:"Duration",
                options:[0,299]
                },
                
                {
                name:"Difficulty",
                options:[
                    {
                    id:0,
                    name:"Beginner",
                    selected:false,
                    },
                    {
                    id:1,
                    name:"Intermediate",
                    selected:false,
                    },
                    {
                    id:2,
                    name:"Moderate",
                    selected:false,
                    },
                    {
                    id:3,
                    name:"Advanced",
                    selected:false,
                    }
                ]
                },
                {
                    name:"Intensity",
                    options:[
                        {
                        id:0,
                        name:"1",
                        selected:false,
                        },
                        {
                        id:1,
                        name:"2",
                        selected:false,

                        },
                        {
                        id:2,
                        name:"3",
                        selected:false,

                        },
                        {
                        id:3,
                        name:"4",
                        selected:false,

                        }
                    ]
                }
            ],
            timeConst:[],
            piCode:null,
            sub_description:null
        };
    },

    mounted() {
        this.$bus.$on('change_vids', (eventData) => {
            console.log("filter instruct", eventData)
            const instructorIndex = eventData.findIndex(category => category.name === "Instructor");
            eventData[instructorIndex].options= [
                {
                    id:this.$route.params.id
                }
            ]
            this.getVideos(eventData)
        })
        this.setup()
    },

    computed: {
        truncatedBio() {
        if (this.instructor.bio.length <= this.maxBioLength) {
            return this.instructor.bio;
        } else {
            return this.showFullBio ? this.instructor.bio : this.instructor.bio.slice(0, this.maxBioLength) + '...';
        }
        }
    },
    methods: {
        toggleBio() {
        this.showFullBio = !this.showFullBio;
        },
        timeto12(time,is_convert){
            if (is_convert) {
                return moment(time, 'HH:mm').format('hh:mm a')

            }else{
                return moment(time, 'HH:mm').add(this.schedule.duration, 'minutes').format('hh:mm a')

            }
        },
        closeBook(){
            this.requestDialog = false
            this.choosenDate = null
            this.timeStart = null
            this.selectPractice = null
            this.selectSubCat = null
            this.pid = null
        },
        adjustTimeSlots(reservedDates, duration, times) {
            let adjustedTimeSlots = [];

            // Convert reserved times to moment objects for easier comparison
            let reservedMoments = reservedDates.map(reservedTime => moment(`2022-01-01T${reservedTime}:00`, 'YYYY-MM-DDTHH:mm:ss'));

            // Iterate over each time slot
            for (let i = 0; i < times.length; i++) {
                let slot = times[i];
                let slotMoment = moment(`2022-01-01T${slot.value}:00`, 'YYYY-MM-DDTHH:mm:ss');

                // Check if the current time slot is within the duration after any reserved time
                let withinDuration = reservedMoments.some(reservedMoment => slotMoment.isBetween(reservedMoment, reservedMoment.clone().add(duration, 'minutes'), null, '[)'));

                // If not within the duration, add the time slot to the adjusted list
                if (!withinDuration) {
                    adjustedTimeSlots.push({
                        label: slot.label,
                        value: slot.value
                    });
                }
            }

            return adjustedTimeSlots;
        },
        subCatEvent(){
            console.log("sadwa", this.selectSubCat)
            this.total = this.selectSubCat.price
            this.sub_description = this.selectSubCat.description || "No Description Available"
            this.isClassDisabled()
        },
        async next(current, nextpage){
            if (current == 1) {
                if (this.choosenDate) {
                    var day = (moment(this.choosenDate).format('ddd')).toLocaleUpperCase()
                    var hasIndex = this.schedule.my_availability.findIndex(obj => obj.name === day);
                    console.log('hasIndex',hasIndex)

                    if (!this.schedule.my_availability[hasIndex].is_available) {
                        alert("This date is unavailble")
                        return false
                    }

                    var getTimes = [];
                    if (hasIndex != -1) {
                        console.log("this.schedule",this.schedule)
                       getTimes = this.schedule.my_availability[hasIndex]
                       var reservedTime = await this.reserved_start_time()

                        var timeS = this.generateTimeSlotsTesting(
                            {
                                start:getTimes.start,
                                end:getTimes.end,
                                duration:this.schedule.duration,
                                endBuffer:30,
                                reserved: reservedTime
                            }
                        )
                        this.timeConst = timeS

                        console.log("recoif",timeS)
                        this.step=this.step +1;

                       return false
                    }
                    
                }else{
                    alert("Please choose a date")
                }
            }
        },
        generateTimeSlots(start,end,duration_p,buffer,reserved, interval ){
            var timeSlots = [];
            let currentTime = moment(start, 'HH:mm');

            // Generate time slots with the specified interval
            while (currentTime.isSameOrBefore(moment(end, 'HH:mm'))) {
                const slotStart = currentTime.format('HH:mm');

                // Check if the current slot is within any reserved time range
                const isReservedSlot = reserved.some(reservedSlot => {
                    return moment(slotStart, 'HH:mm').isSameOrAfter(moment(reservedSlot.start, 'HH:mm')) &&
                        moment(slotStart, 'HH:mm').isBefore(moment(reservedSlot.end, 'HH:mm'));
                });

                if (!isReservedSlot) {
                    timeSlots.push({
                        label: moment(slotStart, 'HH:mm').format('hh:mm A'),
                        value: slotStart
                    });
                }

                currentTime.add(interval, 'minutes');
            }
            // Add buffer time at the end of the schedule
            if (buffer > 0) {
                const lastSlot = timeSlots[timeSlots.length - 1];
                const bufferTime = moment.duration(buffer, 'minutes');
                lastSlot.start = moment(lastSlot.start, 'HH:mm').subtract(bufferTime).format('HH:mm');
            }
            const todaysdate = moment().format('YYYY-MM-DD');
            console.log("outes", timeSlots)

            if (this.choosenDate == todaysdate) {
                const currentTimeString = moment().format('HH:mm');

                timeSlots = timeSlots.filter(slot => slot.value >= currentTimeString);
                var threeHoursLater = moment().add(3, 'hours').format('HH:mm');
                console.log("renovaytye", start)

                timeSlots = timeSlots.filter(slot => slot.value >= threeHoursLater);
            }
           

            return timeSlots
        },
        isReservedTime(slotStart, reserved) {
            return reserved.some(reservedSlot => {
                return moment(slotStart, 'HH:mm').isSameOrAfter(moment(reservedSlot.start, 'HH:mm')) &&
                    moment(slotStart, 'HH:mm').isBefore(moment(reservedSlot.end, 'HH:mm'));
            });
        },
        reserved_start_time(){
            var mod = [];
            // Assuming `this.req` returns a promise
            return this.req('p_setup', 'reserved_start_time', {date: this.choosenDate, instructor_id: this.instructor.id})
                .then((data) => {
                    return data.map((itm) => {
                        itm = itm.split(' ')[1];
                        mod.push({
                            start: itm,
                            end: moment(itm, 'HH:mm').add(this.schedule.duration, 'minutes').format('HH:mm')
                        });
                    });
                })
                .then(() => {
                    return mod; // returning mod inside the promise chain
                });

        },
        generateCode() {
            const date = moment();
            const code = date.format("YYMMDDHHmm");
            return code;
        },

        isClassDisabled(){
            console.log("rem");
            var ret = true;
            if (this.timeStart && this.total != 0) {
                ret = false
            }
            return ret
        },
        payClass(){
            this.loadbtn = true
            var security_key = this.PAYMONGO_KEY
            if (this.isGodMode) {
                security_key = 'c2tfdGVzdF9GUmdlTjJ0UkI1Q1M3YmdXTXhqU1pMMlg6c2tfdGVzdF9GUmdlTjJ0UkI1Q1M3YmdXTXhqU1pMMlg'
            }
           
           const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: `Basic ${security_key}`
            },
            body: JSON.stringify({
                data: {
                attributes: {
                    send_email_receipt: false,
                    show_description: true,
                    show_line_items: true,
                    line_items: [
                    {
                        currency: 'PHP',
                        amount:parseInt(this.total+'00'),
                        name: `Payment For Private Class`,
                        quantity: 1,
                        description: `Payment for Instructor ${this.instructor.name} [ID:${this.instructor.id}] from Student ${this.$store.state.name} [${this.$store.state.user_id}]`
                    }
                    ],
                    payment_method_types: ['card', 'dob', 'dob_ubp', 'gcash', 'grab_pay', 'paymaya'],
                    description: `Payment for Instructor ${this.instructor.name} [ID:${this.instructor.id}] from Student ${this.$store.state.name} [${this.$store.state.user_id}]`,
                    cancel_url: `${this.mainUrl}/reroute/${this.piCode}/${this.schedule.duration}/cancel`,
                    success_url: `${this.mainUrl}/reroute/${this.piCode}/${this.schedule.duration}/success`
                }
                }
            })
            };

            fetch('https://api.paymongo.com/v1/checkout_sessions', options)
            .then(response => response.json())
            .then(async ({data}) => {
                console.log("LEFT ME NO CHOICE",data);
                var extra = {
                    start_date: moment(this.choosenDate).format('YYYY-MM-DD'),
                    start: this.timeStart,
                    end: moment(this.timeStart, 'HH:mm').add(this.schedule.duration, 'minutes').format('HH:mm'),
                    name:this.instructor.name,
                    category:this.selectPractice.id,
                    sub_category:this.selectSubCat.id,
                    instructor_id: this.instructor.id,
                    user_id: this.$store.state.user_id,
                    reference_id: this.piCode,
                    price:this.total
                }
                await this.req('p_setup','create_private',extra).then((data)=>{
                })
                window.location =data.attributes.checkout_url

            })
            .catch(err => console.error(err));
        },
        practiceChoseEvent(item){
            console.log("thise", this.selectPractice)
            this.subCats = this.selectPractice.subcategories
            this.isClassDisabled()
        },
        generateTimeSlotsTesting(schedule) {
            const { start, end, duration, endBuffer, reserved } = schedule;
            const timeSlots = [];
            const interval = 30; // Static interval of 30 minutes
            console.log("yowwo", start)
            let currentTime = moment(start, "HH:mm");
            const endTime = moment(end, "HH:mm");
            const currentTimePlus2Hours = moment().add(2, 'hours');

            while (currentTime.isBefore(endTime)) {
                let slotEnd = moment(currentTime).add(duration, 'minutes');
                let slotAvailable = true;

                // Checking if the current slot overlaps with any reserved dates
                for (let i = 0; i < reserved.length; i++) {
                    const reservedStart = moment(reserved[i].start, "HH:mm");
                    const reservedEnd = moment(reserved[i].end, "HH:mm");
                    if (currentTime.isSameOrBefore(reservedEnd) && slotEnd.isSameOrAfter(reservedStart)) {
                        slotAvailable = false;
                        break;
                    }
                }

                if (slotAvailable) {
                    const slotStartFormatted = currentTime.format('h:mm A'); // Format start time in 12-hour format
                    timeSlots.push({ label: slotStartFormatted, value: currentTime.format('HH:mm') }); // Push formatted start time with original value
                }
                currentTime = moment(currentTime).add(interval, 'minutes');
            }
            // Remove time slots that are past
          
            const todaysdate = moment().format('YYYY-MM-DD');
            console.log("outes", timeSlots)

            if (this.choosenDate == todaysdate) {
                const currentTimeFormatted = moment().format('HH:mm');
                const filteredTimeSlots = timeSlots.filter(slot => slot.value >= currentTimeFormatted);
                const filteredTimeSlots2Hours = filteredTimeSlots.filter(slot => {
                    const slotTime = moment(slot.value, 'HH:mm');
                    return slotTime.isAfter(currentTimePlus2Hours);
                });
                return filteredTimeSlots2Hours;

            }
            // Remove time slots that are 2 hours after the current time
            
            return timeSlots;
        },
        async setup(){
            await this.getSingleInstructor();
            await this.getCat()
            var newFilter = JSON.parse(JSON.stringify(this.originalFilter))
            newFilter.splice(2, 0,{
                name:"Instructor",
                options:[
                    {
                        id:this.$route.params.id
                    }
                ]
            }
            )
            console.log("sefaeas",newFilter);
            await this.getVideos(newFilter)
        },
        bookAClass(){
            if (!this.$store.state.isLogin) {
                this.$router.push({name:'Login'})
            }
            this.piCode = this.generateCode()

            var extra = {
                instructor_id: this.instructor.id,
            }
            this.req('p_setup','get_schedule',extra).then((data)=>{
                this.requestDialog = true
                data.my_availability = JSON.parse(data.my_availability)
                data.practices = JSON.parse(data.practices)
                console.log("practicess", data.practices)
                this.schedule = data

                this.schedule.my_availability.map((itm)=>{
                    const uppercaseDay = itm.name.toUpperCase();

                    if (itm.is_available) {
                    // Remove the day from the excludeThisDays array if it exists
                        const index = this.excludeThisDays.indexOf(uppercaseDay);
                        if (index !== -1) {
                            this.excludeThisDays.splice(index, 1);
                        }
                    } else {
                        // Add the day to the excludeThisDays array if it doesn't exist
                        if (!this.excludeThisDays.includes(uppercaseDay)) {
                            this.excludeThisDays.push(uppercaseDay);
                        }
                    }
                })
                
                
            });
        },


        allowedDates(val) {
            const dayOfWeek = moment(val).format('ddd').toUpperCase();
            return !this.excludeThisDays.includes(dayOfWeek);
        },
        selectVideo(video){
            this.videos = video
            console.log("this.$route.params.category,", video.vid_id)
            this.$router.push({
                name: 'singleVid',
                params: {
                    category: this.$route.params.category,
                    video_id: video.vid_id,
                },
            });            // this.setupVideoPlayer(video)
        },
        getVideos(data){
             this.$bus.$emit('change_loading', true)

            this.nodeReq('videos/get_videos/',{filter:data}).then((data)=>{
                this.videos = data
                this.vid_count = data.length
                this.$bus.$emit('change_loading', false)
            });
        },
        getCat(){
            
            this.nodeReq('category/get_filter_category/',{id:this.$route.params.id}).then((data)=>{
                this.originalFilter[0].options = data
                console.log("nien", this.instructor);
            });
        },
        getSingleInstructor(){
            var index = this.$store.state.instructors.findIndex(instructor => instructor.instructor_id === this.$route.params.id);
            this.instructor = this.$store.state.instructors[index]

        },

        getInstructor(){
            this.nodeReq('instructor/get_instructor_single/',{id:this.$route.params.id}).then((data)=>{
                this.instructor = data
                console.log("nien", this.instructor);
                this.checkDescriptionLength();
            });
            this.nodeReq('user/getUserStatus/',{id:this.$store.state.user_id}).then((data)=>{
                if (data.user_type == 'god_mode') {
                    this.isGodMode = true
                }else{
                    this.isGodMode = false
                }
            });
        },
        checkDescriptionLength() {
            const elem = this.$refs.descriptionText;
            console.log("WOK", elem.clientHeight)
            if (elem.scrollHeight > elem.clientHeight) {
                this.showMore = true;
            }
        },
        toggleShowLess() {
            this.showLess = !this.showLess;
        },
        openThyFilter(){
            console.log("pass original filter",this.originalFilter)
            this.$bus.$emit('openFilter', this.originalFilter)
        },
    },
};
</script>

<style lang="scss" scoped>
.show-more {
  overflow: hidden;
}

.show-less {
  -webkit-line-clamp: 5; /* Limit number of lines to display */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.single-view{
    max-height: 90vh;
    overflow: auto;

    .header{
        position: relative;
        width: 100%;
        video{
            width: 100%;
            height: 250px;
        }
    }

    
    .sec-header{
        color:#fff;
        padding: 20px 15px 0px;
        display: flex;
        justify-content: space-between;
    
    }

    .third-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 15px;
        color:#fff;
        .input-holder{
            width: 60%;
            display:flex;
            align-items: center;
            h5{
                margin-right: 10px;
            }
            .j-select{
                width: 50%;
            }
        }
    }

    .list-container{
        max-height: 77vh;
        overflow: auto;
        padding:10px 20px;
    }
    .bio{
        margin-top: 55px;
        color: #fff;
        padding: 20px;
        font-size: 15px;
        text-align: center;
        .book-button{
            margin: 0px 0px 20px;
            .btn-booking{
                background: #066b43;
                -webkit-backdrop-filter: blur(18px);
                backdrop-filter: blur(18px);
                border: 1px solid #066b43;
                color: #fff!important;
               
            }
        }
    }

    .avatar-holder{
        z-index: 5;
        width: 100%;
        padding: 20px;
        bottom: -61px;
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: #fff;
        .avatar-img{
            margin-top: 20px;
            border: 3px solid #000;
        }
    }
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>