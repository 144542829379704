<template>
  <div v-if="remainingTime > 0" class="countDown">
    <VueCountdown :time="remainingTime" @end="countDownEnd">
      <template slot-scope="props">
        <span v-if="props.days">{{ props.days }} days, </span>
        <span v-if="props.hours">{{ props.hours }} hr, </span>
        <span v-if="props.minutes">{{ props.minutes }} m, </span>
        <span>{{ props.seconds }} s</span>
      </template>
    </VueCountdown>
  </div>
  <div v-else>
    <span>Event has started!</span>
  </div>
</template>

  
<script>
import moment from 'moment-timezone';
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'DawataAppCountDownComponent', 
  components: { VueCountdown },
  props: {
    start: {
      type: String, // Assuming the start time is passed as a string
      required: true
    }
  },
  data() {
    return {
      remainingTime: 0
    };
  },
  mounted() {
    this.calculateRemainingTime();
  },
  methods: {
    countDownEnd(){
      this.$emit('countdown-finished');
    },
    calculateRemainingTime() {
      const eventStartTime = moment(this.start);
      const now = moment();
      const duration = eventStartTime.diff(now);

      this.remainingTime = duration > 0 ? duration : 0;
    }
  }
};
</script>

  
  <style lang="scss" scoped>
  .countDown {
    font-size: 14px;
    font-weight: bold;
    color: #d3d3d3; /* Adjust the color as needed */
  }
  </style>
  