<template>
    <v-container class="d-flex justify-center align-center pa-10" fill-height>
      <v-row justify="center" align="center" class="text-center">
        <v-col cols="12">
          <v-icon style="font-size:150px" color="primary">mdi-comment-multiple</v-icon>
        </v-col>
        <v-col cols="12">
          <p v-if="!has_feedback" style="color:#fff">We would love to hear your feedback on the live class. Please rate and share your thoughts below.</p>
          <p v-else style="color:#fff">Do you want to change your feedback for this class?</p>

        </v-col>
        <v-col cols="12">
          <v-rating
            v-model="rating"
            length="5"
            large
            color="yellow"
            background-color="grey lighten-1"
            dense
          ></v-rating>
        </v-col>
        <v-col cols="12">
          <v-textarea
          dark
            v-model="feedback"
            label="Your Feedback"
            outlined
            rows="4"
          ></v-textarea>
        </v-col>
        <v-col v-if="!has_feedback" cols="12">
          <v-btn color="primary" @click="submitFeedback">Submit Feedback</v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn color="primary mr-4" @click="updateFeedBack">Update Feedback</v-btn>
          <v-btn color="error" @click="$router.push({name:'LiveView'})">No </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        rating: 0,
        feedback: '',
        live_id:null,
        has_feedback:false,
        feedback_id:null,
        feedback_created:false
      };
    },
    mounted(){
        this.getLiveClassId()
    },
    methods: {
      getLiveClassId(){
        this.nodeReq('mobile/live_classes/get_live_id/',{mn:this.$route.params.mn_number}).then((data)=>{
              // this.instructor = data
              console.log("sa id,", data) 
              this.live_id = data
              this.getFeedBack()

          });
      },
      generateUniqueId() {
        return 'id-' + Date.now() + '-' + Math.floor(Math.random() * 1000);
        },  
      submitFeedback() {
       
        var extra = {
            feed_id: this.generateUniqueId(),
            user_id:this.$store.state.user_id,
            target_id: this.live_id ,
            type:"feedback",
            rating:this.rating,
            message:this.feedback
        }

        this.nodeReq('mobile/live_classes/create_feedback/',extra).then((data)=>{
              // this.instructor = data
              this.$router.push({name:"LiveView"})
        });
      },
      updateFeedBack(){
        var extra = {
            feed_id: this.feedback_id,
            rating:this.rating,
            message:this.feedback
        }

        this.nodeReq('mobile/live_classes/update_feedback/',extra).then((data)=>{
              // this.instructor = data
              this.$router.push({name:"LiveView"})
        });
      },
      getFeedBack() {
        var extra = {
            user_id:this.$store.state.user_id,
            target_id: this.live_id ,
        }

        this.nodeReq('mobile/live_classes/get_feeback/',extra).then((data)=>{
            if (data.length != 0) {
                this.has_feedback = true
                this.feedback_id = data[0].feed_id
                this.rating = data[0].rating
                this.feedback = data[0].message
            }else{
                this.has_feedback = false
                this.feedback_id = null
            }
        });
     },
     
    },
  };
  </script>
  
  <style scoped>
  </style>
  