import { render, staticRenderFns } from "./forgotpasswordPage.vue?vue&type=template&id=1c82c508&scoped=true"
import script from "./forgotpasswordPage.vue?vue&type=script&lang=js"
export * from "./forgotpasswordPage.vue?vue&type=script&lang=js"
import style0 from "./forgotpasswordPage.vue?vue&type=style&index=0&id=1c82c508&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c82c508",
  null
  
)

export default component.exports