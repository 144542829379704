<template>
    <div class="maintenance" >
        <div style="color:#fff; padding: 30px;">
            <h1>Yoga Maintenance Time</h1>
            <p>We are currently fixing some issues to make your yoga experience even better.</p>
        </div>
    </div>
</template>

<script>
export default {
    created(){

    },
    mounted(){

    },
    data(){
        return{
            view:'home'
        }
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
.maintenance{
    width: 100%; height: 98vh;
    background: 
        linear-gradient(
            rgba(0, 0, 0, 0.5), 
            rgba(0, 0, 0, 0.5)
        ), 
        url('https://d2hyqud1qblstw.cloudfront.net/system/underwork.webp');
    background-size: cover;
}
</style>
