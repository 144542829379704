<template>
    <div v-if="isLoading"  class="spinner-load">
        <div class="content" :class="isFromLive ? 'content-studio' : 'content'">
            <div class="spinner"></div>
            <p>
                Patience in yoga is the key to unlocking wellness, as it allows us to embrace the journey with grace, cultivating strength and balance from the inside out.
            </p>
        </div>
      
    </div>
</template>

<script>
    export default {
        props:['isLoading','isFromLive']
    }
</script>

<style lang="scss" scoped>
.spinner-load{
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2000;
    background: rgb(28 28 28 / 56%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.15);
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: center;
    }
    .content-studio{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: center;
        transform: rotate(90deg);
    }
    p{
        font-size: 23px;
        font-weight: bold;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ee9a0a;
        width: 320px;

    }
}
.spinner {
   position: relative;
   width: 88px;
   height: 88px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 25px;
}

.spinner::before,
.spinner::after {
   border: 10.6px solid #ee9a0a;
   border-radius: 50%;
   position: absolute;
   content: '';
   display: block;
}

.spinner::before {
   width: 52.8px;
   height: 52.8px;
   border-bottom-color: transparent;
   border-left-color: transparent;
   animation: spinner-1o3y8q 0.8999999999999999s infinite linear reverse;
}

.spinner::after {
   animation: spinner-1o3y8q 0.6s infinite linear;
   height: 88px;
   width: 88px;
   border-right-color: transparent;
   border-top-color: transparent;
}

@keyframes spinner-1o3y8q {
   to {
      transform: rotate(360deg);
   }
}
</style>


