import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/videos/home.vue';
import Category from '../views/videos/category_video.vue';
import SingleVideo from '../views/videos/singleVideo.vue';
import Login from '../views/videos/login.vue';
import Register from '../views/videos/register.vue';
import Playlist from '../views/playlist/page.vue';
import PlaylistVid from '../views/playlist/playlistVid.vue';

import Courses from '../views/courses/page.vue';

import Instructor from '../views/instructor/page.vue';
import InstructorSingle from '../views/instructor/page_view.vue';
import LiveView from '../views/liveVideo/page.vue';
import TempPage from '../views/liveVideo/tempPage.vue';
import Profile from '../views/profile/page.vue';
import MyClasses from '../views/liveVideo/myclasses.vue';
import ReRouter from '../views/liveVideo/reRouter.vue';
import Feedback from '../views/liveVideo/feedback.vue';
import UpdatePage from '../views/updatePageApp.vue';
import Host from '../views/liveVideo/host.vue';
import AfterPayment from '../views/liveVideo/afterPayment.vue';
import Maintenance from '../views/maintenance.vue'; // Assuming you have a Maintenance component
import Store from '../store/index';
import ForgotPasswordPage from '../views/forgotpasswordPage.vue'

Vue.use(VueRouter);

function checkIfUser(to, from, next) {
    const userIsLoggedIn = Store.state.isLogin;

    if (userIsLoggedIn) {
        next();
    } else {
        next(false);
    }
}

function checkNavigation(to, from, next) {
    const relevantFromPath = from.fullPath.split('/').slice(0, 4).join('/');
    const relevantToPath = to.fullPath.split('/').slice(0, 4).join('/');

    if (relevantFromPath === relevantToPath) {
        // Handle the situation, e.g., ignore the navigation
        next(false);
    } else {
        next();
    }
}

const routes = [
    {
        path: '/forgot_password_page/:email',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/after_payment/:id?/:type',
        name: 'AfterPayment',
        component: AfterPayment,
    },
    {
        path: '/update_page/',
        name: 'UpdatePage',
        component: UpdatePage,
    },
    {
        path: '/feedback/:mn_number',
        name: 'Feedback',
        component: Feedback,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/host/:mn_number/:passcode',
        name: 'Host',
        component: Host,
    },
    {
        path: '/reroute/:pid?/:duration?/:type',
        name: 'ReRouter',
        component: ReRouter,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/playlist',
        name: 'Playlist',
        component: Playlist,
        beforeEnter: checkIfUser, // Attach the guard to this route
    },
    {
        path: '/playlist/:id',
        name: "Playlist_Video",
        component: PlaylistVid,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/vids/:category/:id',
        name: "Category_view",
        component: Category,
    },
    {
        path: '/vids/:category/:id/:video_id',
        name: "singleVid",
        component: SingleVideo,
    },
    {
        path: '/instructor',
        name: "Instructor",
        component: Instructor,
    },
    {
        path: '/instructor/:id',
        name: "InstructorView",
        component: InstructorSingle,
    },
    {
        path: '/courses',
        name: "Courses",
        component: Courses,
    },
    {
        path: '/live/:id?/:sheet?/:type?',
        name: "LiveView",
        component: LiveView,
    },
    {
        path: '/my_classes',
        name: "MyClasses",
        component: MyClasses,
    },
    {
        path: '/live_temp/',
        name: "TempPage",
        component: TempPage,
    },
];
// Maintenance mode handling
const isMaintenance = false; // Assuming this is your maintenance mode variable

// If maintenance mode is enabled, redirect all routes to the maintenance page
if (isMaintenance) {
    routes.forEach(route => {
        route.beforeEnter = (to, from, next) => {
            next({ path: '/maintenance' });
        };
    });
}

// Add the maintenance route at the end
routes.push({
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance // Assuming you have a Maintenance component
});
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});





export default router;
