<template>
    <div class="cat_vids">
        <div class="header">
            
            <h2>COURSES</h2>
        </div>
        <div class="sec-header"> 
            <h5>Showing {{ course_count }} {{ course_count >1 ?'Courses' :'Course'}}</h5>
            <v-btn @click="openThyFilter()"  color="#fff"  elevation="2"  outlined  x-small>FILTER</v-btn>
        </div>
       
        <div class="list-container">
            <div v-for="(itm, index) in courses" :key="index">
                <v-card :style="computedBackgroundStyle(itm)" style="border-radius:10px">
                    <!-- Card content goes here -->

                    <div class="course-title">
                        <small><i>20 Videos</i></small>
                        <h4>{{ itm.title.toUpperCase() }} DEEPEST FEAR</h4>
                        <div class="mt-2">
                            <v-btn small block>Buy Course</v-btn>
                        </div>
                    </div>
                    
                </v-card>
                <div class="lower-info">
                    <div>
                        <small>3.8</small>
                        <small>Enricke Janu Morales</small>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import { LogLevel } from 'amazon-ivs-player';

export default {
    name: 'DawataAppCategoryVideo',
    components:{
      
    },
    data() {
        return {
            courses:[],
            course_count:0,
         };
    },
    
    computed: {
        computedBackgroundStyle() {
            return (itm) => {
                return {
                    backgroundImage: `linear-gradient(to bottom, rgb(0 0 0 / 31%), rgb(0 0 0 / 75%)), url('${itm.thumbnail || itm.avatar}')`,
                    height: '250px',
                    backgroundSize: 'cover', // Ensures the image covers the card
                    backgroundPosition: 'center', // Centers the image
                };
            };
        }
    },
    mounted() {

        
       this.setup()
    },

    methods: {
        async setup(){
            // await this.getSubCats()
            // await this.getInstructors()
            await this.getCourses()
        },
        getSubCats(){
          
        },
        getInstructors(){
           
        },
       
        getCourses(data){
            this.$bus.$emit('change_loading', true)
             this.nodeReq('/courses/get_courses',{}).then((data)=>{
                console.log('nemo', data)
                this.courses = data
            });
        },
       
    },
};
</script>

<style lang="scss">
 .j-select{
        .v-input__control{
            min-height: 25px!important;
        }
    }  

</style>

<style lang="scss" scoped>

.cat_vids{
    font-family: 'Andada Pro', serif;

}
.header{
    text-align: center;
    color:#fff;
    h2{
        color:#81593F;
    }
}
.sec-header{
    color:#fff;
    padding: 10px 15px 0px;
    display: flex;
    justify-content: space-between;
   
}

.third-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 15px;
    color:#fff;
    .input-holder{
        width: 300px;
        display:flex;
        align-items: center;
        h5{
            margin-right: 10px;
        }
        .j-select{
            width: 50%;
        }
    }
}

.list-container{
    max-height: 77vh;
    overflow: auto;
    padding: 30px 30px 10px;
    position:relative;

    .course-title{
        position:absolute;
        bottom:0px;
        color:#fff;
        font-family: 'Andada Pro', serif;
        // font-weight: bold;
        width: 100%;
        padding: 10px;
        h4{
            font-family: 'Andada Pro', serif !important;
        }
    }

    .lower-info{
        color: #fff;
    }
}

</style>