<template>
    <div>
        <div>
            <v-system-bar v-if="userType == 'free_2_wk'" height="30" color="primary" dark>All class are free for you</v-system-bar>

            <JDatePicker @dateChange="getTheLiveDate"/>

            <v-lazy  v-if="!isloading"  :min-height="'76vh'" :options="{'threshold':0.5}" transition="fade-transition"  style="min-height: 76vh; max-height: 76vh; overflow: auto;">
                <template v-if="live.length !== 0">
                    <LiveRenderItem :events="live" :isfree="userType == 'free_2_wk'" @goToLive="goToLive"/>
                </template>
                <template v-else>
                    <div class="no-video">
                    <v-icon class="icon-n" color="#fff">mdi-weight-lifter</v-icon>
                    <h3>Oops No Live Classes Available</h3>
                    <p>Yoga is the journey of the self, through the self, to the self. In the nothingness of meditation, we find everything.</p>
                    </div>
                </template>
            </v-lazy>
            <v-row v-else>
                <v-col style="padding:14px">
                    <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
                    <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
                    <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-model="cancelDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                 Cancel your reservation
                </v-card-title>

                <v-card-text>
                    {{ cancelMessage }}
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click="cancelDialog = false"
                >
                    Nope
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    @click="cancelReservation(chosenLive)"
                >
                    Yes, Im sure to cancel
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-bottom-sheet v-model="showfilterLive"   >
           <FilterLive @filter="filterNow" :count="liveStuff.length" @filterTrigger="filt" @reset="reset"/>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="waitingArea"  persistent fullscreen>
            <v-sheet class="waiting-area" style="height: 100%; position: relative; padding: 0px;">
               <v-btn class="close-btn" @click="closeBottomSheet()"><v-icon>mdi-close</v-icon></v-btn>
               <div class="instructor-info">
                    <v-avatar size="50" class="img"> <img :src="chosenLive.ins_avatar || 'https://dawata.s3.ap-southeast-2.amazonaws.com/system/default_playlist.png'" alt="John"></v-avatar>
                    <div class="info-data">
                        <span>{{ chosenLive.instructor }}</span>
                    </div>
               </div>
               <div class="live-info">
                    <div class="title-live">{{ chosenLive.name }}</div>
                    <div class="schedule"> {{  getTime(chosenLive.start) }} - {{ getTime(chosenLive.end)  }} : {{  getDuration(chosenLive.start, chosenLive.end) }}</div>
                    <div class="chosen_live_info">
                        <div >
                            <v-avatar tile :size="25" class="mr-2"> 
                                <img :src="chosenLive.flag || 'https://dawata.s3.ap-southeast-2.amazonaws.com/system/default_playlist.png'" alt="John"> 
                            </v-avatar> 
                            {{ chosenLive.flag_name || 'Country Not Available' }}
                        </div>
                        <div>
                            {{chosenLive.language}}
                        </div>
                    </div>
               </div>
               <div class="live-description">
                    <div style="font-weight:bold"><i>About the class</i></div>
                    <p>{{ chosenLive.description }}</p>
               </div>


               <div v-if="chosenLive.type == 'group_class' && chosenLive.price == 0 || userType == 'free_2_wk'" style="position: absolute; width: 100%; bottom: 0px; padding: 20px;" >
                    <template v-if="isTimeInRange(chosenLive.start, chosenLive.end)">
                        <template v-if="!chosenLive.reserve" >
                            <v-btn :loading="loadbtn" @click="reserveClass(chosenLive, true)" class="join-btn" color="error" block>
                                RESERVE THIS CLASS FOR FREE
                                <template v-slot:loader>
                                    <span class="custom-loader">
                                    <v-icon light>mdi-cached</v-icon>
                                    </span>
                                    Please Wait
                                </template>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn @click="getMetoClass(chosenLive)" class="join-btn" color="success" block>JOIN THIS CLASS</v-btn>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="!chosenLive.reserve">
                            <template>
                                <v-btn :loading="loadbtn" @click="reserveClass(chosenLive, true)" class="join-btn" color="error" block>
                                    RESERVE THIS CLASS FOR FREE
                                    <template v-slot:loader>
                                        <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                        </span>
                                        Please Wait

                                    </template>
                                </v-btn>
                            </template>
                        </template>
                        <template v-else>
                            <div></div>
                            <div class="timer-based">
                                <Countdown v-if="showCountdown" :start="chosenLive.start" @countdown-finished="handleCountdownFinished"/>

                                <!-- <span> Class will start</span><br>
                                <v-chip style="color:#fff; margin-bottom: 10px;" color="orange" label>{{ countdown.hours }} h :{{ countdown.minutes }} m :{{ countdown.seconds }} s</v-chip> -->
                                <v-btn @click="openCancelRequest(chosenLive, true)" class="join-btn" color="success" block>CANCEL RESRVATION</v-btn>
                            </div>
                        </template>
                    </template>
                </div>
                <div v-else-if="reRender" class="action-div pa-20">
                    <template v-if="isTimeInRange(chosenLive.start, chosenLive.end)">
                        <template v-if="!chosenLive.reserve" >
                            <!-- <v-btn @click="reserveClass(chosenLive)" class="join-btn" color="error" block>RESERVE THIS CLASS FOR {{chosenLive.type == 'teacher_group_class' ? chosenLive.price : '150'}} PHP</v-btn> -->
                            <v-btn :loading="loadbtn" @click="reserveClass(chosenLive)" class="join-btn" color="error" block>
                                RESERVE THIS CLASS FOR {{chosenLive.price}} PHP
                                <template v-slot:loader>
                                    <span class="custom-loader">
                                    <v-icon light>mdi-cached</v-icon>
                                    </span>
                                    Please Wait

                                </template>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn @click="getMetoClass(chosenLive)" class="join-btn" color="success" block>JOIN THIS CLASS</v-btn>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="!chosenLive.reserve">
                            <template>
                                <v-btn :loading="loadbtn" @click="reserveClass(chosenLive)" class="join-btn" color="error" block>
                                    RESERVE THIS CLASS FOR {{ chosenLive.price }} PHP
                                    <template v-slot:loader>
                                        <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                        </span>
                                        Please Wait

                                    </template>
                                </v-btn>
                            </template>
                        </template>
                        <template v-else>
                            <div></div>
                            <div class="timer-based">
                                <Countdown v-if="showCountdown" :start="chosenLive.start" @countdown-finished="handleCountdownFinished"/>
                                <v-btn @click="openCancelRequest(chosenLive)" class="join-btn" color="success" block>CANCEL RESRVATION</v-btn>
                            </div>
                        </template>
                    </template>
               </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import { formToJSON } from 'axios';
import moment from 'moment-timezone';
import JDatePicker from './components/jdatepicker.vue'
import FilterLive from './components/filter.vue'
import LiveRenderItem from './components/liveRenderItems.vue'
import Countdown from './components/countdown.vue'

    export default {
        components:{
            JDatePicker,
            FilterLive,
            LiveRenderItem,
            Countdown
        },
        data: () => ({
            loadbtn:false,
            isGodMode:false,
            events: [], 
            activeDate: null,
            numDays: 800, // Number of days to display
            notLiveYet:false,           
            categories: [ 'Studio 1','Studio 2'],  
            live: [],          
            intervalId:null,
            isloading:true,
            waitingArea:false,
            chosenLive:[],
            countdown: {},
            timerInterval: null,
            reRender:true,
            cancelDialog:false,
            cancelMessage:'',
            refundable:false,
            showDateDialog:false,
            dateType:'month',
            datePicker:moment().format('YYYY-MM-DD'),
            showfilterLive:false,
            originalTimezone:'Asia/Manila',
            originalLive:[],
            liveStuff:[],
            showCountdown: true,
            userType:"regular",
        }),
        created(){
            const currentDateTime = moment().format('YYYY-MM-DD');
            // this.getLive(currentDateTime)
            // console.log("created", this.live)
        },
        mounted () {    
            this.$bus.$on('new_live', (eventData) => {
                this.getLive( moment().format('YYYY-MM-DD'))
            })
            //    this.callApiFunction()
            if(this.$store.state.isLogin){
                this.nodeReq('user/getUserStatus/',{id:this.$store.state.user_id}).then((data)=>{
                    if (data.user_type == 'free_2_wk') {
                        this.userType = 'free_2_wk'
                    }
                });
            }
           
           this.getLive( moment().format('YYYY-MM-DD'))

            this.$nextTick(() => {
                console.log("meamos", this.live)
           });
           
        },
        computed: {
            startDate() {
                const localTimezone = moment.tz.guess();
                console.log("localTimezone", moment().tz(localTimezone).startOf('day'))
                return moment().tz(localTimezone).startOf('day');
            },        
            dates() {
                const dates = [];
                for (let i = 0; i < this.numDays; i++) {
                const date = moment(this.startDate).add(i, 'days');
                if (date >= this.startDate) {
                dates.push(date);
                }
            }
            return dates;
            },
          
        },
        beforeDestroy() {
            // Clear the interval when the component is destroyed
            clearInterval(this.intervalId);
            clearInterval(this.timerInterval);

        },
        methods: {
            handleCountdownFinished() {
                this.showCountdown = false;
            },
            reset(){
                this.showfilterLive = false
                this.live =this.originalLive
            },
            filt(){
                this.showfilterLive = false
                this.live = this.liveStuff
            },
            filterNow(data){
                const filteredData = this.live.filter(item => {
                    const countryMatch = data.countries.includes(item.ins_country.name.common);
                    console.log("ANIMEAL", countryMatch)
                    const instructorMatch = data.instructors.includes(item.instructor);
                    return countryMatch || instructorMatch;
                });
                this.liveStuff = filteredData
                if (data.countries.length == 0 && data.instructors.length == 0) {
                    this.liveStuff= this.originalLive
                }
                console.log("Filtered Data:", filteredData);
            },
            openLiveFilter(){
                this.showfilterLive = true
            },
            closeBottomSheet(){
                this.waitingArea = false
                if (this.$route.params.id) {
                    this.$router.push({name:'LiveView'})
                }
            },
            getTheLiveDate(val){
                console.log("emitted", val)
                this.getLive(val)
            },
            showBotDialog(type){
                this.showDateDialog = true,
                this.dateType = type
            },
          

            callApiFunction() {
                const timezone = moment.tz.guess();
                const now = moment().tz(timezone);
                const startHour = moment().tz(timezone).hour(6);
                const endHour = moment().tz(timezone).hour(21);

                if (now.isBetween(startHour, endHour, null, '[)')) {
                    const currentDateTime = now.format('YYYY-MM-DD');
                    this.getLive(currentDateTime);
                } else {
                    clearInterval(this.intervalId);
                }
            },
           

            isTimeInRange(startTime, endTime) {
                // Detect the local timezone
                const localTimezone = moment.tz.guess();
                const adjustedStartTime = moment.tz(startTime, this.originalTimezone).tz(localTimezone).subtract(10, 'minutes');
                const currentTime = moment().tz(localTimezone);
                return currentTime.isBetween(adjustedStartTime, endTime);
            },


            getTime(date) {
                // Specify the original timezone (e.g., 'Asia/Manila' for PH time)
                // Get the current timezone automatically
                const localTimezone = moment.tz.guess();
                // Convert the date from the original timezone to the local timezone
                const convertedTime = moment.tz(date, 'YYYY-MM-DD HH:mm', this.originalTimezone).tz(localTimezone);
                // Log the converted time
                console.log("Original time:", date, "Converted time:", convertedTime.format('hh:mm A z'));

                // Return the formatted time
                return convertedTime.format('hh:mm A');
            },
            openCancelRequest(item, skip = false){
                const itemStartTime = moment(item.start);
                const currentTime = moment();
                const differenceInHours = itemStartTime.diff(currentTime, 'hours');
                this.cancelDialog = true

                if (skip) {
                    this.cancelMessage = "Hello and warm greetings! We hope you're looking forward to our upcoming class as much as we are.  Your wellness journey means a lot to us! 🌟"
                    this.refundable = false
                    return false
                }

                if (differenceInHours < 2) {
                    // Refund cannot be processed if less than 3 hours before start time
                    this.cancelMessage = "Hello and warm greetings! We hope you're looking forward to our upcoming class as much as we are. 😊 Just a gentle heads-up: our cancellation policy kindly asks for cancellations to be made at least two hours prior to the class. Unfortunately, refunds won't be possible outside of this window. We truly appreciate your cooperation and understanding as we strive to offer the best experience for everyone. Should you have any questions or need assistance, please don't hesitate to let us know. Your wellness journey means a lot to us! 🌟"
                    this.refundable = false
                }else{
                    this.cancelMessage = "Hello there! Just wanted to give you a quick heads-up that your refund is will be on its way to your account. Keep an eye out for it! If you have any questions or need further assistance, feel free to reach out. Thanks for your patience and understanding!"
                    this.refundable = true
                }
            },
            cancelReservationImprot(live_id){
                this.req('p_setup','cancel_reservation',{live_id:live_id, user_id:this.$store.state.user_id}).then((data)=>{
                    const currentDateTime = moment().format('YYYY-MM-DD');
                    this.reRender = false
                    var chosenLiveIndex = this.live.findIndex(item => item.live_id === live_id);
                    this.chosenLive = this.live[chosenLiveIndex]
                    this.reRender = true
                })
            },

            cancelReservation(item){
                const options = {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                        authorization: `Basic ${this.PAYMONGO_KEY}`
                    }
                };


                if (this.refundable) {
                   fetch(`https://api.paymongo.com/v1/checkout_sessions/${item.pi_id}`, options)
                    .then(response => response.json())
                    .then(({data}) => {
                        var upperMain = data.attributes
                        var amount_refund = upperMain.payments[0].attributes.amount
                        var id = upperMain.payments[0].id
                        const options_inside = {
                            method: 'POST',
                            headers: {
                                accept: 'application/json',
                                'content-type': 'application/json',
                                authorization: `Basic ${this.PAYMONGO_KEY}`
                            },
                            body: JSON.stringify({
                                data: {
                                attributes: {amount: amount_refund, payment_id: id, reason: 'requested_by_customer'}
                                }
                            })
                        };

                        fetch('https://api.paymongo.com/refunds', options_inside)
                        .then(response => response.json())
                        .then(({data}) => {
                            this.req('p_setup','cancel_reservation',{live_id:item.live_id,  user_id:this.$store.state.user_id}).then((data)=>{
                                const currentDateTime = moment().format('YYYY-MM-DD');
                                this.cancelDialog = false
                                this.$router.push({name:'LiveView'})

                                this.refundable = false
                                this.getLive(currentDateTime)
                            })
                        })
                        .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
                }else{
                    this.req('p_setup','cancel_reservation',{live_id:item.live_id, user_id:this.$store.state.user_id}).then((data)=>{
                        const currentDateTime = moment().format('YYYY-MM-DD');
                        this.cancelDialog = false
                        this.$router.push({name:'LiveView'})

                        this.refundable = false
                        this.getLive(currentDateTime)
                    })
                }
            },
            getPriceRate(items){
                this.nodeReq('rate_converter/convert_price',{
                   items:items,
                   targetCurrency:"PHP"
                }).then((data)=>{
                    this.live = data
                })
            },
            async getLive(date) {
                this.$bus.$emit('change_loading', true);

                const localTimezone = moment.tz.guess();
                const currentMoment = moment();
                
                try {
                    const data = await this.req('p_setup', 'm_get_live', { date: date, user_id: this.$store.state.user_id });
                    
                    const filteredLive = data.filter(event => moment.tz(event.end, this.originalTimezone) > currentMoment);
                    this.live = filteredLive.map(itm => {
                    itm.ins_country = JSON.parse(itm.ins_country);
                    return itm;
                    });

                    this.originalLive = this.live;

                    this.getPriceRate(this.live);

                    if (this.$route.params.id) {
                    const liveIndex = this.live.findIndex(item => item.live_id === this.$route.params.id);
                    if (liveIndex !== -1) {

                        if (this.$route.params.sheet === 'open') {
                            if (this.$route.params.type === 'success') {
                                // await this.nodeReq('mobile/live_classes/update_to_paid', { id: this.$route.params.id });
                                this.chosenLive.reserve = true;
                                this.goToLive(this.live[liveIndex]);

                            } else {
                                await this.cancelReservationImport(this.$route.params.id);
                                this.chosenLive.reserve = false;
                                this.goToLive(this.live[liveIndex]);

                            }

                        }
                    }
                    }

                    if (this.waitingArea) {
                    const chosenLiveIndex = this.live.findIndex(item => item.live_id === this.chosenLive.live_id);
                    if (chosenLiveIndex !== -1) {
                        this.chosenLive = this.live[chosenLiveIndex];
                    }
                    }

                } catch (error) {
                    console.error('Error fetching live data:', error);
                } finally {
                    this.isloading = false;
                    this.$bus.$emit('change_loading', false);
                }
            },
            getDuration(start, end){
                const startDate = moment(start, 'YYYY-MM-DD HH:mm');
                const endDate = moment(end, 'YYYY-MM-DD HH:mm');
                const duration = moment.duration(endDate.diff(startDate));
                var isMany = ' min'

                if (duration.asMinutes() > 0) {
                    isMany = ' mins'
                }
                return duration.asMinutes() + isMany;
            },
           
            isActive(date) {
             return this.activeDate !== null && this.activeDate.isSame(date, 'day');
            },
            toggleActive(date) {
                this.isloading = true;
                console.log("uooho", moment(date).format('YYYY-MM-DD'))
                if (this.activeDate && this.activeDate.isSame(date, 'day')) {
                    this.activeDate = null;
                } else {
                    if (!date.isSame(moment(), 'day')) {
                        this.activeDate = date;
                    }
                }

                this.getLive(moment(date).format('YYYY-MM-DD'))
            },
           
            reserveClass(item,skipPayment = false) {

                console.log("item bi");
                this.loadbtn =true
                
                var security_key = this.PAYMONGO_KEY
                if (this.isGodMode) {
                    alert("this is a test")
                    security_key = 'c2tfdGVzdF9GUmdlTjJ0UkI1Q1M3YmdXTXhqU1pMMlg6c2tfdGVzdF9GUmdlTjJ0UkI1Q1M3YmdXTXhqU1pMMlg'
                }

                if (skipPayment) {
                    var extra = {
                            liveId: this.chosenLive.live_id,
                            userId: this.$store.state.user_id,
                            status:"paid",
                            paymentIntentId:null
                        }
                    this.req('p_setup','reserve_class',extra).then((responde)=>{
                        const currentDateTime = moment().format('YYYY-MM-DD');
                        // this.getLive(currentDateTime)
                        
                        window.location =  `${this.mainUrl}/after_payment/${item.live_id}/success`
                    })

                    return false
                }
                    
                var textCon = Math.floor(item.price) +'00'
                  var  price = parseInt(textCon)
             
                const options = {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        authorization: `Basic ${security_key}`
                    },
                    body: JSON.stringify({
                        data: {
                        attributes: {
                            send_email_receipt: false,
                            show_description: true,
                            show_line_items: true,
                            line_items: [
                            {
                                currency: 'PHP',
                                amount: price,
                                name: `Class ${item.name} Pass`,
                                quantity: 1,
                                description: "Payment for live class with id " + item.live_id  + ' and instructor name ' + item.name + ' from ' + this.$store.state.name
                            }
                            ],
                            payment_method_types: ['card', 'dob', 'dob_ubp', 'gcash', 'grab_pay', 'paymaya'],
                            description:`Payment for Live Class With  ${item.name} [GURU ID:${item.instructor_id}] [Live ID:${item.live_id}] from Student ${this.$store.state.name} [${this.$store.state.user_id}]`,
                            cancel_url: `${this.mainUrl}/after_payment/${item.live_id}/cancel`,
                            success_url: `${this.mainUrl}/after_payment/${item.live_id}/success`,
                            // success_url: `${this.mainUrl}/host/${item.mn_number}/${item.passcode}`
                        }
                        }
                    })
                    };

                    fetch('https://api.paymongo.com/v1/checkout_sessions', options)
                    .then(response => response.json())
                    .then(async({data}) => {
                        console
                        var extra = {
                            liveId: this.chosenLive.live_id,
                            userId: this.$store.state.user_id,
                            status:"unpaid",
                            paymentIntentId:data.id
                        }
                        await this.req('p_setup','reserve_class',extra).then((responde)=>{
                            const currentDateTime = moment().format('YYYY-MM-DD');
                            // this.getLive(currentDateTime)
                            
                        })
                        window.location =data.attributes.checkout_url

                    })
                    .catch(err => console.error(err));
                return false
            },
            getMetoClass(item){
                // this.$router.push(`/host/${item.mn_number}/${item.passcode}`);
                window.location.href = `${this.mainUrl}/host/${item.mn_number}/${item.passcode}`
                // window.location.href = `http://192.168.0.242:8082/mobile/${item.mn_number}/${item.passcode}/test`;
            },
            goToLive(item){
                if (this.$store.state.isLogin) {
                    console.log("ROKOOOO",item.ins_country.country)
                    this.waitingArea = true
                    this.chosenLive = item
                    this.chosenLive['flag'] = item.ins_country.flags.svg || null
                    this.chosenLive['flag_name'] = item.ins_country.name.common || null
                    this.nodeReq('user/getUserStatus/',{id:this.$store.state.user_id}).then((data)=>{
                        if (data.user_type == 'god_mode') {
                            this.isGodMode = true
                        }else{
                            this.isGodMode = false
                        }

                    });
                }else{
                    this.$router.push({name:'Register'})
                }
           
            }
           
        },
    }
</script>

<style lang="scss" scoped>
.event-n-render{
    display: flex;
    color: #fff;
    padding: 10px;
    border-radius: 12px;
    color: #fff!important;
    margin-bottom: 20px;
    flex-direction: column;
    font-family: "Andada Pro", serif;

    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    .event-n-render-1{
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        flex: 1;
        .live-box-upcoming{
            border-radius: 5px;
            color: white;
            text-align: center;
            font-size: 10px;
            padding: 2px 10px;
            font-family: "Andada Pro", serif;
            background: rgb(19, 19, 19);
            font-weight: bold;
        }
          .live-box-live{
            border-radius: 5px;
            color: white;
            text-align: center;
            font-size: 10px;
            padding: 2px 10px;
            font-family: "Andada Pro", serif;
            background: red;
        }

        .event-n-time{
            display: flex;
        }
    }
    .event-n-render-1-5{
        display:flex;
        justify-content:space-between;
        font-size:12px;
        margin-top:15px
    }
    .event-n-render-2{
        margin-top:15px;

        .title{
            font-size: 19px!important;
            font-weight: bold;

        }
        .subtitle{
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
        }
    }
    .event-n-render-3{
        display: flex;
        justify-content: space-between;
        margin-top:10px;
        align-items: center;

        .instructor-sec{
            display: flex;
            align-items: center;
        }
    }

}

.date-list {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 16px;
  color:#fff;
  font-family: "Andada Pro", serif;

  .date-month{
      font-size: 12px;
  }
}

.today {
  background-color: #f0f0f0;
  color:#84593f;
  font-weight:bold
}

.active {
  background-color: #84593f;
  color:#f0f0f0;
  font-weight:bold
}


.date-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  min-width: 60px;
}

.day {
  font-weight: bold;
}

.date-number {
  margin-top: 4px;
}
.event-list{
    min-height: 74vh;
    padding:20px 17px 
}

// .date-box:hover {
//   background-color: #e0e0e0;
// }


.live-list{
    display: flex;
    align-items: center;
    padding:5px 10px 0px;
   
    border-radius: 12px;
    color: #fff!important;
    margin-bottom: 20px;

    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    .avatar{
        padding:10px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .content{
        p{
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
        }
    }

    .time-frame{
        width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .time{
            font-size:12px;
            font-weight:bold;
            margin-bottom:10px;
        }
        .live-box-live{
            border-radius: 5px;
            color: white;
            text-align: center;
            font-size: 10px;
            padding: 2px 10px;
            font-family: "Andada Pro", serif;
            background: red;
        }

        // .live-box-upcoming{
        //     border-radius: 5px;
        //     color: white;
        //     text-align: center;
        //     font-size: 10px;
        //     padding: 2px 10px;
        //     font-family: "Andada Pro", serif;
        //     background: rgb(38, 38, 38);
        // }
    }
}

.waiting-area{
    // font-family: "Andada Pro", serif;

    background: rgba(0, 0, 0, 0.483)!important;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 11.5px );
    -webkit-backdrop-filter: blur( 11.5px );
    // border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );
    // border: 1px solid rgba( 255, 255, 255, 0.18 );

    .timer-based{
        text-align: center;
        color: #fff;
        font-family: 'Andada Pro';
        div {

        }
    }

    .close-btn{
        position: fixed;
        top: 0px;
        right: 0px;
        border-radius: 40px;
        padding: 10px;
        background: transparent!important;
        color: #fff!important;
        box-shadow: none!important;
    
    }

    .live-info{
        padding: 15px;
        color: white;
        font-family: "Furom", serif;

        .title-live{
            font-size: 20px;
            font-weight: bold;
        }
        .schedule{
            margin-top:10px;
            font-size: 14px;
        }
        .chosen_live_info{
            margin-top:10px;
            display:flex;
            justify-content:space-between
        }
    }

    .live-description{
        padding: 20px;
        min-height: 46%;
        max-height: 46%;
        overflow: auto;
        font-family: "Furom", serif;
        color: #fff;
    }
    .instructor-info{
        padding:20px 20px 0px;
        display: flex;
        align-items: center;
        font-weight: bold;
        .img{
            margin-right: 10px;
        }
        .info-data{
            color: #fff;
            font-family: "Andada Pro", serif;
            font-size: 20px;
        }
    }
    .action-div{
        position: absolute;
        width: 100%;
        padding: 10px;
        bottom: 25px;
        left: 0;
        .join-btn{
            height: 30px;
            border-radius: 45px;
            color: white;
            background: transparent;
            border: 0px!important;
            box-shadow: unset;
        }
        
    }
}

.no-video{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    gap: 20px;
    font-family: "Andada Pro", serif;

    .icon-n{
        font-size: 95px;
        color: #fff;

    }
    p{
        font-size: 13px;
        text-align: center;
        font-style: italic;
    }
}

.header{
    text-align: center;
    color:#fff;
    margin: 15px 0px;

    h2{

    }
}


</style>