<template>
    <div class="" style="height: 93vh; overflow: hidden;">
        <div class="header">
            <h2 style="font-family: 'Andada Pro', serif; color:#81593F"> INSTRUCTORS </h2>
        </div>
        <div class="third-header"> 
            <!-- <div class="input-holder">
                <h5>Sort By: </h5>  
                <v-select class="j-select" :items="sorts" v-model="sortedVid" label="Sort" :height="20" solo dense hide-details></v-select>
            </div>
            <div class="btn-actions">
                <v-btn   color="#fff" icon elevation="2"    x-small><v-icon>mdi-grid-large</v-icon></v-btn>
                <v-btn   color="#fff" icon elevation="2"    x-small><v-icon>mdi-menu</v-icon></v-btn>
            </div> -->
        </div>
        <v-lazy v-if="!isloading" style="max-height: 72vh; overflow-x: hidden; overflow-y: auto;" :min-height="'72vh'" :options="{'threshold':0.5}" transition="fade-transition" >
            <v-row class="instructor-list">
                <v-col  :xs="12" :sm="6" :md="4" :lg="2" v-for="(vid, index) in instructor" :key="index" :index="index" class="vid-list-holder" @click="selectInstructor(vid)">
                    <div class="vid-holder">
                        <v-overlay  :absolute="true"></v-overlay>
                        <video autoplay muted loop width="100%" height="160px" :src="vid.avatar_timeline " playsinline></video>
                        <v-avatar class="avatar-img" size="80">
                            <img :src="vid.avatar || 'https://dawata.s3.ap-southeast-2.amazonaws.com/system/default_playlist.png'"  alt="John">
                        </v-avatar>
                        <div v-if="vid.schedule_count != 0" class="rate-holder">
                           Personal Class Available
                        </div>
                    </div>
                
                    <div class="header-content">
                        <h4 style="color:#81593F">{{ vid.name || "Instructor Name"}}</h4>
                    </div>
                    <div class="description-holder">
                    {{  vid.bio  }}
                    </div>
                </v-col>
            </v-row>
        </v-lazy>
        <v-row v-else >
            <v-col style="padding:30px">
                <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
                <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
                <v-skeleton-loader class="card-loader" type="card-avatar"></v-skeleton-loader>
            </v-col>
        </v-row>

    
    </div>
</template>

<script>
export default {
    name: 'DawataPage',

    data() {
        return {
            isloading:true,
            sorts:['NEWEST','OLDEST'],
            sortedVid:'NEWEST',
            instructor:[
               
            ],
        };
    },

    mounted() {
        
        this.renderInstructor()
    },

    methods: {
       
        renderInstructor(){
            this.isloading = true;
            this.instructor = this.$store.state.instructors
            this.isloading = false;
        },

        getInstructors(){
            this.$bus.$emit('change_loading', true)
            this.nodeReq('instructor/get_instructor/',{}).then((data)=>{
                this.instructor = data
               
                this.$nextTick(() => {
                    this.isloading = false;
                });
                console.log("nien", this.instructor);
            });
        },
        selectInstructor(instructor){
            this.$router.push({
                name: 'InstructorView',
                params: {
                    id: instructor.instructor_id,
                },
            }); 
        }
    },
};
</script>

<style lang="scss">
 .j-select{
        .v-input__control{
            min-height: 25px!important;
        }
    }  

</style>

<style lang="scss" scoped>
.instructor-list{
    padding: 0px 20px;
    font-family: 'Andada Pro', serif;
    .avatar-img{
        position: absolute;
        bottom: -40px;
        left: 21px;
        z-index: 6;
    }
    .description-holder{
            padding-top: 12px;
         display: -webkit-box;
        -webkit-line-clamp: 4; /* Number of lines you want to display */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 8em;
        text-align: center;
        font-size: 13px;

    }
    .vid-list-holder{
            position: relative;
            background: transparent;
            color: #fff;
            margin-top: 20px;

        .header-content{
            padding: 10px 10px 10px 120px;
        }
        .vid-holder{
            position:relative;
            width: 100%;
            video{
                width: 100%;
                height: 200px;

            }

            .rate-holder{
                padding: 5px 10px;
                border-radius: 10px;
                z-index: 30;
                position: absolute;
                right: 10px;
                bottom: 22px;
                background: #84593f;
                font-size: 12px;
                font-weight: bold;
                font-family: 'Andada Pro', serif;

            }
        }
    }
}
.header{
    text-align: center;
    color:#fff;
    margin: 15px 0px;

    h2{

    }
}
.sec-header{
    color:#fff;
    padding: 10px 15px 0px;
    display: flex;
    justify-content: space-between;
   
}



.third-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 15px;
    color:#fff;
    .input-holder{
        width: 60%;
        display:flex;
        align-items: center;
        h5{
            margin-right: 10px;
        }
        .j-select{
            width: 50%;
        }
    }
}



.list-container{
    max-height: 77vh;
    overflow: auto;
    padding-top: 10px;
}
</style>