<template>

    <v-row>
        <v-col class="videoList-container" :lg="2" :md="6" :sm="23" :xs="12" v-for="(vid, index) in allVideos" :key="index" :index="index" @click="selectVideo(vid)">
            <v-card class="vid-list-holder" >
                <v-img
                :src="vid.thumbnail"
                class="white--text align-end vid-img"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
                >   
                    <div class="duration-div">
                        <div class="duration-item">
                            {{ (vid.duration / 60).toFixed(2) }}
                        </div>
                    </div>
                    <div class="cat-div">
                        <div class="cat-item">
                            {{ vid.cat_name.toUpperCase()}} | {{ vid.sub_name.toUpperCase() }}
                        </div>
                    </div>
                 
                    <div class="sys-div">
                        <div class="sys-item">
                            <span>INTENSITY {{ vid.intensity }}</span> | <span> {{ vid.level.toUpperCase() }}</span>
                        </div>
                    </div>
                    <v-overlay :absolute="true">
                        <v-icon class="overlay-icon">mdi-play-circle</v-icon>
                    </v-overlay>
                </v-img>
                <div class="header-content">
                    <h3>{{ vid.title }}</h3>
                    <div class="intructor-holder-title">
                        <v-avatar size="30">
                        <img :src="vid.avatar"  alt="John">
                        </v-avatar>
                        <div class="title-holder">
                            <h5>{{ vid.ins_name || "JOHN DOE"}}</h5>
                        </div>
                    </div>
                </div>
                <div class="description-holder">
                    {{ vid.description }}
                </div>
            </v-card>
        </v-col>
    </v-row>
  
</template>

<script>
export default {
    name: 'DawataAppListOfVids',
    props:["videos","isRelatedVideo"],
    data() {
        return {
            allVideos:[]
        };
    },
    watch: {
        videos(newValue, oldValue) {
            console.log("roadset", newValue)
            this.allVideos = newValue
        }
    },
    mounted() {
        this.allVideos = this.videos
        // console.log("roadset 12", this.videos)

    },

    methods: {
        selectVideo(vid){
            console.log("roko",vid.title, vid.vid_id, this.isRelatedVideo)

            if (this.isRelatedVideo) {
                this.$emit("changeVideo",vid)
            }else{
                this.$emit("selectVid",vid)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .videoList-container{
        padding: 15px 5px 0px;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // gap: 20px;
        @media (max-width: 535px) {
            flex-direction: column;
            align-items: stretch;
        }
        .header-content{
            text-align: center;
            padding: 10px 0px 0px;
            h3{
                color: #81593F;

            }
            .intructor-holder-title{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding-top: 10px;
            }
        }
        .description-holder{
            padding: 10px 10px 0px;
            max-height: 4 * 1.2em; /* 1.2em is the line height */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Number of lines to show */
            -webkit-box-orient: vertical;
            text-align: center;

        }
        .vid-img{
            margin-bottom: 0px;
        }

        .vid-list-holder{
           
            cursor: pointer;
            position: relative;
            margin: 0px 0px 40px;
            background: transparent;
            color: #fff;
            // @media (max-width: 535px) {
            //     width: 100%;
            // }
        }
        .instructor-holder{
            display: flex;
            align-items: center;
            padding: 10px 0px 0px;
            .title-holder{
                margin-left: 10px;
                .rate-span{
                    font-size: 12px;
                }
            }
        }
        .video-holder{
            .video-data-holder{
                video{
                    height: 250px;
                    width: 100%;
                }
            }
        }
        .overlay-icon{
            font-size: 50px;
        }
        .duration-div{
            display: flex;
            align-items: end;
            justify-content: end;
            padding: 10px;
            z-index: 10;

            .duration-item{
                color: #fff;
                padding: 5px;
                font-size: 12px;
                background-color: black;
            }
        }
        .cat-div{
            display: flex;
            align-items: end;
            justify-content: end;
            position: absolute;
            top: 0;
            background: #fff;
            padding: 2px;
            z-index: 10;
            .cat-item{
                color: #000;
                font-size: 14px;
                padding: 5px 10px;
            }
        }

        .subcat-div{
            display: flex;
            align-items: end;
            justify-content: end;
            position: absolute;
            top: 0;
            background: #fff;
            padding: 2px;
            z-index: 10;
            .subcat-item{
                color: #000;
                font-size: 14px;
                padding: 5px 10px;
            }
        }


        .sys-div{
            display: flex;
            align-items: end;
            justify-content: end;
            position: absolute;
            bottom: 0;
            padding: 2px;
            z-index: 10;
            .sys-item{
                color: #fff;
                font-size: 14px;
                padding: 5px 10px;
            }
        }
    }

</style>