<template>
    <div>
        <v-dialog v-model="updateModal" persistent fullscreen >
            <v-card>
                <!-- <v-img :src=""></v-img> -->
                <v-card-title class="text-h5">
                 We have a new version {{ currentVersion }}
                </v-card-title>
                <v-card-subtitle>
                  Source: {{os}}WV-JANU-6112024  
                </v-card-subtitle>
                <v-card-text>
                    Hey there! 
                    We apologize for any trouble you might be experiencing. To help resolve these issues, please ensure your app is up to date. If the button below doesn't direct you to the app store, you can manually search for our app in the store and update it from there. Thanks for your understanding and support—namaste! 🧘‍♀️🧘‍♂️
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    dark
                    block
                    @click="updateVerion"
                >
                    Update
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                versionNumber:null,
                updateModal:false,
                currentVersion:null,
                os:null
            }
        },
        mounted(){
            this.checkVersion()
        },
        methods:{
            async checkVersion(){
                // if (!window.appOS) {
                //     this.updateModal = true
                //     return false
                // }
                // await window.app
                this.nodeReq('user/get_version/',{get_version:window.appOS}).then((data)=>{
                this.currentVersion = data.version
                console.log("rokoonn", window)
                this.appVersion = window.appVersion;
                this.os = window.appOS
                if (data.version ==  this.appVersion) {
                    this.updateModal = false
                }else{
                    this.updateModal = true
                    this.versionNumber = this.appVersion
                }
                // this.$store.dispatch('updateToCurrentVersion', {mobile_version:data});
                //   if (data) {
                //     this.updateModal = false
                //   }else{
                //     this.updateModal = true
                //   }
                }).catch((err)=>{
                    console.log("huh", err)
                });
            },
            updateVerion(){
                // this.req('p_user','update_version',{id:this.$store.state.user_id, version:this.version}).then((data)=>{
                    this.$store.dispatch('updateToCurrentVersion', {mobile_version:this.versionNumber});

                    setTimeout(()=>{
                        if( window.ReactNativeWebView){
                            window.ReactNativeWebView.postMessage('appUpdater');

                        }
                        // this.updateModal = false

                    }, 1000)

                //             window.location.reload(true)//https://play.google.com/store/apps/details?id=com.dawata_wellness.app

                //   window.location.reload(true)
                // })
                // this.nodeReq('user/update_version/',{id:this.$store.state.user_id}).then((data)=>{
                //   window.location.reload(true)
                // }).catch((err)=>{
                //     console.log("huh", err)
                // });
                
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>