<template>
    <div class="category-holder">
       <h3 style="color:#fff; margin-bottom:29px">LIVE CLASSESS</h3>
       <v-row class="category-container">
        <v-col style="padding:10px 5px;" :xs="12" :sm="6" :md="4" :lg="2" v-for="(cat, index) in categories" :key="index" :index="index" @click="changeView(cat.name)">
            <v-card >
                <v-img
                :src="cat.image"
                class="white--text align-end category-item"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="170px"
                >   
                    <v-card-title v-text="cat.name"></v-card-title>
                    <!-- <v-card-subtitle>({{ cat.count }}) videos</v-card-subtitle> -->
                </v-img>
            </v-card>
        </v-col>
       </v-row>
        <!-- <div  class="category-container">
            <v-card v-for="(cat, index) in categories" :index="index" @click="changeView(cat.name,cat.id)">
                <v-img
                :src="cat.image"
                class="white--text align-end category-item"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="170px"
                >   
                    <v-card-title v-text="cat.name"></v-card-title>
                </v-img>
            </v-card>
        </div> -->
      
      
    </div>
</template>

<script>
import ListOfVideos from '@/components/ListOfVids.vue'
import SingleVideo from '@/components/singleViewVideo.vue'
import { mapActions } from 'vuex';

export default {
    name: 'DawataAppCategory', 
    components:{
        ListOfVideos,
        SingleVideo
    },
    data() {
        return {
            viewType:'',
            realView:'',
            categories:[
                {
                    name:"YUTA",
                    id:998,
                    image:'https://dawata.s3.ap-southeast-2.amazonaws.com/system/temporaryLive/1.png',
                    count:1
                },
                {
                    name:"HANGING",
                    id:999,
                    image:'https://dawata.s3.ap-southeast-2.amazonaws.com/system/temporaryLive/2.jpg',
                    count:1
                },
            ],
            videos:null,
        };
    },
    watch: {
        viewType(newValue, oldValue) {
            this.realView = newValue
        }
    },

    mounted() {
      
    },

    methods: {
        changeView(item){
            if (item == 'YUTA') {
                this.$router.push({name:'Yuta'})
            }else{
                this.$router.push({name:'Hanging'})

            }
        }

    },
};
</script>

<style lang="scss" scoped>
.category-holder{
    overflow-x: hidden;
    padding: 20px;

    .category-container{
        .category-item{
          
        }
    }
    

    

}
</style>