<template>
    <div class="registration">
        <LoadingSpinner :isLoading="loading"></LoadingSpinner>
        <div class="wizardClass" v-if="e13 == 1">
                <div class="title-1">
                    <h3 class="">Sign Up</h3>
                    <p>Hey there, intrepid wellness voyager! So, our app is still in the oven, baking up a storm. We swear we're sprinkling it with extra 'oomph' to make it worth the wait. Your patience is like a fine wine—ageing gracefully and improving with time. Stay tuned for a wellness adventure that'll make you laugh, cry, and probably sweat a bit too. We're crafting something special just for you!"</p>
                </div>
                <div class="socials1">
                   <!-- <v-btn class="btn-sign-act" block>CONTINUE WITH GOOGLE</v-btn>
                    <v-btn class="btn-sign-act" block>CONTINUE WITH FACEBOOK</v-btn> -->
                </div>
                <v-form ref="formregister" v-model="valid">
                    <v-text-field
                        dark
                        dense
                        outlined
                        v-model="firstName"
                        :rules="firstNameRules"
                        label="First Name"
                        color="#fff"
                        required
                    ></v-text-field>
                    <v-text-field
                        dark
                        dense
                        outlined
                        v-model="lastName"
                        :rules="lastNameRules"
                        label="Last Name"
                        color="#fff"
                        required
                    ></v-text-field>
                    <v-text-field
                        dark
                        dense
                        outlined
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        color="#fff"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :rules="passwordRules"
                        label="Password"
                        color="#fff"
                        type="password"
                        dark
                        dense
                        outlined
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="confirmPassword"
                        :rules="[v => confirmMatch(password, confirmPassword) || 'Passwords do not match',
                     v => !!v || 'Passwords is required',]"
                        label="Confirm Password"
                        color="#fff"
                        type="password"
                        dark
                        dense
                        outlined
                        required
                    ></v-text-field>
                    <div style="display: flex; align-items: center; color: #fff; ">
                        <v-checkbox dark v-model="tou_aggreement" @click="openTou = true; tou_aggreement"  :rules="[v => v || 'This box must be checked',]"  required> </v-checkbox>
                        <div @click="openTou = true"> By checking this box, I accept the <a href="javascript::void(0)" @click.prevent="openTou = true">Terms of Use</a> and <a href="javascript::void()" @click.prevent="openTou = true">Privacy Policy</a></div>
                    </div>
                </v-form>
                <v-btn
                    color="primary"
                    block
                    style="margin-top: 25px;"
                     @click="updateWizard()"
                >
                    Next
                </v-btn>
                
                <!-- <v-btn
                    color="primary"
                    block
                    style="margin-top: 25px;"
                     @click="createAccount()"
                >
                    Create Account
                </v-btn> -->
        </div>
        <div class="wizardClass" v-else-if="e13 == 2">
            <div class="title-1">
                <h3 class="">Student Waiver</h3>
                <p>Complete your registration process by acknowledging the "Explore the Journey Within: Yoga Student Waiver" - your final step towards joining our community.</p>
            </div>
            <v-divider dark style="height: 2px"></v-divider>
            <div style="color: #fff;">
                **Waiver of Liability Acknowledgement for Students**
                <br><br>
                I, {{ firstName }} {{ lastName }}, hereby acknowledge that my participation as a student on the Dawata Wellness platform ("the Platform") involves certain inherent risks and uncertainties. By accessing or using the Platform, I voluntarily assume all risks associated with my participation, including but not limited to physical injuries, emotional distress, and property damage.
                <br><br>

                I understand and acknowledge that:
                <br><br>

                1. **Nature of Activities**: The activities conducted through the Platform may include physical exercises, meditation practices, and educational sessions, among others. I understand that these activities may pose certain risks, and I agree to participate with caution and mindfulness.
                <br><br>

                2. **Responsibility for Personal Safety**: I acknowledge that I am responsible for my own safety and well-being during my participation on the Platform. I agree to follow any instructions provided by teachers and to use my own judgment when engaging in activities.
                <br><br>

                3. **Release of Liability**: I hereby release Dawata Wellness, its officers, directors, employees, agents, and affiliates from any and all liability for injuries, damages, or losses that may arise directly or indirectly from my participation on the Platform. This release applies to all claims, including those based on negligence or breach of contract.
                <br><br>

                4. **Protection for Teachers**: I understand that the teachers on the Dawata Wellness platform operate as independent contractors and are solely responsible for the services they provide. I agree that any disputes or claims related to the services provided by teachers will be resolved directly between myself and the teacher, and I release Dawata Wellness from any liability in connection with such disputes or claims.
                <br><br>

                5. **Assumption of Risk**: I understand that my participation on the Platform involves certain risks, including the risk of physical injury or emotional discomfort. I voluntarily assume all such risks and agree to hold Dawata Wellness harmless for any consequences that may arise.
                <br><br>

                6. **No Guarantee of Results**: I understand that the results of participating in activities on the Platform may vary and are not guaranteed. I agree to approach my participation with realistic expectations and to focus on my personal growth and well-being.
                <br><br>

                7. **Full Understanding**: I have carefully read and fully understand the terms of this waiver. I acknowledge that I am entering into this agreement voluntarily and of my own free will.
                <br><br>
                <v-form v-model="validStear" ref="waiverForm">
                    <v-checkbox dark v-model="waiver_sign" :rules="[v => v || '',]" label="By checking this box below, I hereby acknowledge and agree to the terms of this waiver of liability." required>  </v-checkbox>
                </v-form>
                

            </div>
            <v-btn color="error" @click="e13--" block style="margin-top: 25px;">Back</v-btn>
            <v-btn color="primary" block style="margin-top: 25px;" @click="openAdivisory">Create Account</v-btn>
        </div>
        <v-bottom-sheet v-model="openTou" persistent fullscreen>
        <v-sheet style="overflow: auto; max-height: 100vh;">
            <v-btn class="mt-6" text color="red" @click="openTou = !openTou; tou_aggreement=false">
            Close
            </v-btn>
            <div class="pa-5" >
                <b><h4>TERMS OF USE</h4></b>
                Greetings, seekers of wisdom and well-being, and welcome to Zen.dawatawellness.com, the tranquil sanctuary where students embark on their journey of self-discovery. By accessing or using this platform, you agree to embrace the following terms and conditions:
                <br><br>
                1. **Acceptance of Terms**: By accessing or using Zen.dawatawellness.com, you acknowledge that you have read, understood, and consent to be bound by these Terms of Use. If you do not agree with any part of these terms, you may not access or use the platform.
                <br><br>
                2. **Registration and Account**: In order to access certain features of Zen.dawatawellness.com, you may be required to register and create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                <br><br>
                3. **Class Enrollment and Attendance**: You have the privilege to enroll in live studio classes, group sessions, or private lessons offered by our esteemed teachers. By enrolling in a class, you commit to attending at the scheduled time and honoring the teachings imparted with respect and mindfulness.
                <br><br>
                4. **Payment and Fees**: Payment for classes and services offered on Zen.dawatawellness.com shall be made through secure payment gateways facilitated by the platform. You agree to pay all applicable fees and charges incurred through your account in a timely manner.
                <br><br>
                5. **Content Consumption**: The platform offers a sanctuary of knowledge through pre-recorded videos and educational materials. By accessing and consuming such content, you acknowledge that the teachings provided are for educational purposes only and should not be construed as medical or professional advice.
                <br><br>
                6. **Code of Conduct**: As fellow travelers on the path of enlightenment, you agree to conduct yourself with reverence, humility, and respect toward teachers and fellow students. Discrimination, harassment, or any form of disruptive behavior will not be tolerated and may result in the termination of your account.
                <br><br>
                7. **Feedback and Reviews**: Your feedback is a sacred offering that guides us on our journey toward continuous improvement. We welcome your constructive feedback and encourage you to share your experiences and insights with the community through reviews and ratings.
                <br><br>
                8. **Intellectual Property Rights**: Respect the intellectual property rights of teachers and content creators on Zen.dawatawellness.com. You agree not to reproduce, distribute, or modify any content on the platform without prior consent.
                <br><br>
                9. **Termination**: Zen.dawatawellness.com reserves the right to terminate or suspend your account at any time, without prior notice or liability, for any reason whatsoever, including but not limited to breaches of these Terms of Use or conduct that disrupts the harmony of the community.
                <br><br>
                10. **Modifications to Terms**: Zen.dawatawellness.com reserves the right to modify or update these Terms of Use at any time, without prior notice. It is your responsibility to review these terms periodically for changes.
                <br><br>
                May your journey on Zen.dawatawellness.com be filled with serenity, growth, and enlightenment as you explore the realms of knowledge and inner peace.

                <b><h4>PRIVACY POLICY</h4></b>

                At Dawata Wellness, we hold the privacy and security of your personal information in the highest regard. This Privacy Policy outlines how we collect, use, disclose, and protect the information you entrust to us. By accessing or using any of our services, including our website, mobile applications, and associated platforms, you consent to the terms of this Privacy Policy.
                <br><br>

                1. **Information We Collect**: We may collect personal information, such as your name, email address, contact details, payment information, and other relevant details when you register an account, make a purchase, or interact with our platform. Additionally, we may collect non-personal information such as device information, log data, and usage patterns.
                <br><br>

                2. **Use of Information**: We use the information we collect to provide and improve our services, personalize your experience, communicate with you, process transactions, and ensure the security of our platform. Your information may also be used for analytics, research, and marketing purposes, provided it is done in accordance with applicable laws and regulations.
                <br><br>

                3. **Data Sharing and Disclosure**: We may share your information with trusted third-party service providers who assist us in operating our platform, conducting business activities, or providing services on our behalf. We may also share information in response to legal requests, to protect our rights or property, or to prevent harm or illegal activities.
                <br><br>

                4. **Data Security**: We implement industry-standard security measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                <br><br>

                5. **Data Retention**: We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When no longer needed, we will securely dispose of or anonymize your information.
                <br><br>

                6. **Your Rights**: You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain processing activities, or to request the transfer of your data to another service provider. Please contact us if you wish to exercise any of these rights.
                <br><br>

                7. **Children's Privacy**: Our services are not directed to children under the age of 13, and we do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately.
                <br><br>

                8. **Changes to this Policy**: We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or through other means.
                <br><br>

                9. **Contact Us**: If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at info@dawatawellness.com.
                <br><br>
                May this Privacy Policy serve as a beacon of transparency and trust, guiding our interactions with mindfulness and respect for your privacy.
                <br><br>
                <p>By clicking the button, I hereby acknowledge that I have read, understood, and agree to abide by the Terms of Use and Privacy Policy. I affirm my consent to all terms outlined therein."</p>
                <v-btn block color="success" @click="tou_aggreement ? aggreeToTerms() :removeAgree()"> {{!tou_aggreement ? 'I Dont Aggree' :'I AGREE'}}</v-btn>
            </div>
        </v-sheet>
        </v-bottom-sheet>  
        <v-dialog v-model="advisory" width="260" persistent>
    
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Dawata Advisory
                </v-card-title>

                <v-card-text>
                    Thank you for using our app! Please note that we're still in beta testing. Your patience is appreciated as we work to improve your experience. If you have any concerns, feel free to email us at dev@dawatawellness.com with subject lines: 'Nice to have feature', 'Bug report', or 'Modification'. We value your feedback!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="createAccount()"
                >
                    Ok! Lets Do It!!
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="betaNotice" width="320">
            <v-card >
                <v-card-title class="text-h5 grey lighten-2">
                    Psst... Just a heads-up,
                </v-card-title>

                <v-card-text>
                    As you embark on this wellness journey with us, expect a few delightful surprises along the way. And by 'delightful surprises,' we mean bugs. Yup, those little critters might sneak their way into our app occasionally, but fear not! We've got our bug squashing team on standby, ready to pounce at any moment. Consider it a test of your Zen-like patience and resilience. Together, we'll conquer glitches and achieve digital nirvana
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="betaNotice = false"
                >
                    Ok
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import LoadingSpinner from '@/components/spinner.vue'

export default {
    name: 'DawataRegister',
    components:{
        LoadingSpinner
    },
    data() {
        return {
            betaNotice:true,
            validStear:false,
            openTou:false,
            tou_aggreement:false,
            waiver_sign:false,
            loading:false,
            advisory:false,
            firstName:'',
            lastName:'',
            email:'',
            valid:false,
            password:'',
            confirmPassword:'',
            firstNameRules: [
                v => !!v || 'First Name is required',
            ],
            lastNameRules: [
                v => !!v || 'Last Name is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            confirmPasswordRules: [
                v => !!v || 'E-mail is required',
            ],
            toPayment:false,
            settings:[],
            paymentType:'Free',
            paymentOption:'',
            afterCreate:{},
            e13: 1,

        };
    },
    watch:{
        e13(newData){
            if (newData == 3) {
                if (this.paymentType == "Free") {
                    this.paymentOption = "free"
                    this.createAccount()
                }
            }
        }
    },

    mounted() {
        
    },

    methods: {
        updateWizard(){
            if (!this.$refs.formregister.validate()) {
                return false
            }

            this.e13++;
            
        },
        aggreeToTerms(){
            this.openTou = false
            this.tou_aggreement = true
        },
        removeAgree(){
            this.openTou = false
            this.tou_aggreement = false
        },
        confirmMatch(password, confirmPassword) {
            return password === confirmPassword;
        },
        proceedToPayment(){
            this.toPayment = true
        },
        goBack(){
            this.toPayment = false
        },
        openAdivisory(){
            if (!this.$refs.waiverForm.validate()) {
                return false
            }
           this.advisory = true 
        },
        createAccount(){
            this.loading = true
            this.advisory = false
            var extra = {
                firstName:this.firstName,
                lastName:this.lastName,
                email:this.email,
                password:this.password,
                paymentOption:this.paymentOption,
                paymentType:'Free'
            }

            this.req('p_user','create_account',extra).then((data)=>{
                console.log("wording",data)
                if(data == 'already-in-use'){
                    alert("The email is already in use")
                    this.e13 = 1
                    this.loading = false
                }else{
                    this.afterCreate = {
                    email:this.email,
                        password:this.password
                    }
                    this.login()
                }
               
                // this.$store.dispatch('saveRegister', data);
            })
        },
        login(){
            this.req('p_user','login',this.afterCreate).then((data)=>{
                var data = {
                    login:true,
                    id:data.id,
                    name:data.name
                }
                this.$store.dispatch('saveLoginData', data);
                this.$router.push({name:"Home"})
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.registration{
    max-height: 90vh;
    overflow: auto;
    .registration_div{
        width: 80%;

        .reg-action{
            margin: 10px 0px;
            text-align: center;
        }
        .textContent{
            color:#fff
        }
        .paymentOption{
            color:#fff
        }
        .paymentMethod{
            color:#fff
        }
    }

    ::v-deep .wizardClass{
        padding: 26px;
        font-family: 'Andada Pro';
        background: unset;
        .v-stepper__label{
            color: #fff;
        }
        .title-1{
            color:#fff
        }
        .socials1{
            .btn-sign-act{
                margin: 10px 0px;
            }
        }
        .v-stepper__content  {
            max-height:80vh;
            overflow: auto;
        }
        .v-stepper__header{
            height: 72px;
        }
       
    }
}
</style>