import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugin/vuetify'
import EventBus from './events/event-bus'
import Pusher from 'pusher-js';
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    console.error('registration:', registrations);

    for (let registration of registrations) {
      registration.unregister();
    }
  }).catch((error) => {
    console.error('Error unregistering service worker:', error);
  });

  // Clear all caches
  if (window.caches) {
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames.map((cacheName) => {
          return caches.delete(cacheName);
        })
      );
    }).then(() => {
      console.log('All caches cleared');
    }).catch((error) => {
      console.error('Error clearing caches:', error);
    });
  }
}

Vue.prototype.$bus = EventBus;
Vue.config.productionTip = false
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.config.productionTip = false
import {
  req,
  nodeReq,
  softDeleteUser,
  getLocationSettings,
} from '../src/components/api-request'

import {generateCodeSystem} from '../src/components/services'

// import './registerServiceWorker'
Vue.mixin({
  data() {
    return {
      sampleMainVideos:[
       
      ],
      sampleMainPlayList:[
        {
          name:"Liked Videos",
          videos:[],
        }
      ],
      apiUrl: process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_ENV === 'sandbox' ? process.env.VUE_APP_SAND_API : process.env.VUE_APP_PROD_API,
      mainUrl: process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_LOCAL_MAINURL : process.env.VUE_APP_ENV === 'sandbox' ? process.env.VUE_APP_SAND_MAINURL : process.env.VUE_APP_PROP_MAINURL,
      PAYMONGO_KEY: process.env.VUE_APP_PAYMENT_TYPE === 'test' ? process.env.VUE_APP_PAYMONGO_TEST : process.env.VUE_APP_PAYMONGO_LIVE,
      version: process.env.VUE_APP_VERSION,
      nodeUrl: process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_LOCAL_NODEURL : process.env.VUE_APP_ENV === 'sandbox' ? process.env.VUE_APP_SAND_NODEURL : process.env.VUE_APP_PROP_NODEURL,
    }
  },
	methods: {
    req,
    nodeReq,
    generateCodeSystem,
    getPusher(){
      // console.log("Init SCHEDEASY v3.0.0 CLIENT", process.env.VUE_APP_ENV);

      

      var pusher = new Pusher('cbf6769772c1b62b1cf5', {
        cluster: "ap1",
      })

      pusher.connection.bind('error', function(error) {
        console.log('Pusher error:', error);
      });
      return pusher;
    },
  },

})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
