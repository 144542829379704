
var exceptionList = ['add_with_billing']

export function req(url,package_entry,extra=null,token=null) {
	var form_data = new FormData();
	console.log("Api reques has been initialized",  this.apiUrl, url);
	let backendUrl = this.apiUrl + url
	// console.log('extra original',extra);
	// let newExtra = deepHandleNULL(extra)
  // handle image upload container
//   var pref = process.env.VUE_APP_ENV  == 'production' ? 'schedeasyV3_' : process.env.VUE_APP_ENV == 'sandbox' ?'sandbox_' : 'schedeasy_'
  var pref = ''

	if (process.env.VUE_APP_ENV  == 'production') {
		pref = 'schedeasyV3_'
	} else if (process.env.VUE_APP_ENV == 'sandbox') {
		pref = 'sandbox_'
	} else{
        pref = 'schedeasy_'
	}
	var data = {
		...extra,
	}
	// console.log("extra after spread", extra);

	const config = {
		headers: { "Content-Type": "multipart/form-data" },
		cancelToken: token,
	};

  if (data.hasOwnProperty('images')) {
    // console.log("images api requestss", data.images.length);
    for (let i = 0; i < data.images.length; i++) {
      form_data.append('image'+(i+1), data.images[i]);
    }
    delete data.images
  }
  if (data.hasOwnProperty('fileList')) {
    // console.log("fileList api requestss", data.fileList);
    for (let i = 0; i < data.fileList.length; i++) {
      form_data.append('file'+(i+1), data.fileList[i]);
    }
    delete data.fileList
  }

  if (data.hasOwnProperty('file')) {
    console.log("file api requestss", data.file);
    form_data.append('file', data.file);
    delete data.file
  }
  if (data.hasOwnProperty('avatar')) {
    form_data.append('avatar', data.avatar);
    delete data.avatar
  }
  if (data.hasOwnProperty('location_logo')) {
    // console.log('location_logo');
    form_data.append('location_logo', data.location_logo);
    delete data.location_logo
  }
  if (data.hasOwnProperty('location_banner')) {
    // console.log('location_banner');
    form_data.append('location_banner', data.location_banner);
    delete data.location_banner
  }
  if (data.hasOwnProperty('promo_footer_img')) {
    // console.log('promo_footer_img');
    form_data.append('promo_footer_img', data.promo_footer_img);
    delete data.promo_footer_img
  }
  if (data.hasOwnProperty('footer_image')) {
    // console.log('footer_image');
    form_data.append('footer_image', data.footer_image);
    delete data.footer_image
  }

	form_data.append("package_entry", package_entry);
	form_data.append("package_api_key", this.apiKey);
	form_data.append("package_data",JSON.stringify(data));

  return new Promise((resolve,reject) => {
    axios.post(backendUrl,form_data,config).then(({data:{success, content}}) => {
      console.log('Request success', package_entry,content)
      if (success) {
				resolve(content)
      } else {
        console.log('else here');
        reject(content)
        // handle error
      }
    }).catch(err => {
			console.log('err',err)
			if (axios.isCancel(err)) {
				// this.toToasterWarning('Request canceled',err.message)
				console.log('Request canceled', err.message);
			} else {
				// this.toToasterWarning('Information',err.message)
				console.log('else')
				// handle error
			}
      reject(err.message)
			throw new Error(err.message);
    })
  })
}

function handleNULL(data) {
	if (typeof data === 'number' || typeof data === 'boolean') {
		return data
	} else {
		return data && data != 'NULL' && data != 'null' ? data : ''
	}
}


export function nodeReq(url,data) {
  var form_data = new FormData();
  form_data.append("package_api_key", this.apiKey);
	form_data.append("package_data",JSON.stringify(data));
  var theUrl = `${this.nodeUrl}${url}`

  return new Promise((resolve,reject) => {
    axios.post(theUrl,form_data).then(({data:{success, content}}) => {
      if (success) {
				resolve(content)
      } else {
        console.log('else here');
        reject(content)
        // handle error
      }
    }).catch(err => {
			console.log('err',err)
			if (axios.isCancel(err)) {
				console.log('Request canceled', err.message);
			} else {
				console.log('else')
			}
      reject(err.message)
			throw new Error(err.message);
    })
  })
}