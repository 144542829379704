<template>
    <div>
      <v-autocomplete
        v-model="selectedCountry"
        :rules="[v => !!v || 'First Name is Required']" required
        :items="filteredCountries"
        item-text="name.common"
        item-value="name.cca2"
        label="Select a Country"
        dense
        outlined
        clearable
        return-object
        @change="selectCountry"
        @search-input="searchCountries"
      >
        <template v-slot:item="{ item }">
          <img :src="item.flags.svg" :alt="item.flags.alt" class="v-img--avatar">
          {{ item.name.common }}
        </template>
        <template v-slot:selection="{ item }">
          <img :src="item.flags.svg" :alt="item.flags.alt" class="v-img--avatar">
          {{ item.name.common }}
        </template>
      </v-autocomplete>
    </div>
</template>

<script>
export default {
  props: {
    countryProp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedCountry: null,
      countries: [],
      searchQuery: ''
    };
  },
  watch:{
    countryProp(newval){
      console.log("watcher country0",newval)
      this.selectedCountry = newval
    }

  },

  computed: {
    filteredCountries() {
      return this.countries.filter(country =>
        country.name.common.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  mounted() {
    console.log("c moundted",this.countryProp)
    this.selectedCountry = this.countryProp
    this.fetchCountries();
  },
  methods: {
    async fetchCountries() {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all?fields=name,flags,cca2');
        const data = await response.json();
        this.countries = data;
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    selectCountry() {
      this.$emit('country_selected', this.selectedCountry)
      console.log('Selected Country:', this.selectedCountry);
    },
    searchCountries(searchQuery) {
      this.searchQuery = searchQuery;
    }
  }
};
</script>

<style scoped>
.v-img--avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px!important;
}
</style>
