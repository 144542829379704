<template>
    <v-sheet class="waiting-area" style="height: 100%; position: relative; ">
      <v-tabs dark vertical>
        <v-tab>Instructor</v-tab>
        <v-tab>Country</v-tab>
        <v-tab>Studios</v-tab>
        <v-tab></v-tab>

        <v-tab-item>
          <v-card style="min-height: 80vh; padding: 10px 10px;">
            <div style="max-height: 80vh; overflow: auto;">
              <v-list subheader>
                <v-list-item v-for="item in filterShowInstructor" :key="item.name" @click="toggleSelectedInstructor(item.name)">
                  <v-list-item-avatar>
                    <v-img :alt="`${item.name} avatar`" :src="item.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="'success'">
                      {{ isSelectedInstructor(item.name) ? 'mdi-check-circle' : '' }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item >
          <v-card >
            <div style="max-height: 80vh; min-height: 80vh; overflow: auto;">
              <v-list subheader>
                <v-list-item v-for="items in countries" :key="items.name.common" @click="toggleSelectedCountry(items.name.common)">
                  <v-list-item-avatar>
                    <v-img :alt="`${items.name.common} avatar`" :src="items.flags.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="items.name.common"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="'success'">
                      {{ isSelectedCountry(items.name.common) ? 'mdi-check-circle' : '' }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item >
          <v-card style="min-height: 80vh;">
            <v-card style="min-height: 80vh;">
            <div style="max-height: 80vh; overflow: auto;">
              <v-list subheader v-if="locations.length != 0">
                <v-list-item v-for="items in locations" :key="items">
                <v-list-item-avatar>
                    <v-img
                      :alt="`${items.name.common} avatar`"
                      :src="items.flags.png"
                    ></v-img>
                  </v-list-item-avatar>
                  
                  <v-list-item-content>
                    <v-list-item-title v-text="items.name.common"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div v-else style="text-align: center;">
                No Studios
              </div>
            </div>
          </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <!-- <div style="display: flex; flex-wrap: wrap; gap: 10px; align-items: center;"> -->
        <!-- <div v-for="(item, index) in locations" :key="index" style="width: 160px">
          <v-card @click="toggleSelection(item)" :class="{ 'selected': item.selected }">
            <v-img height="150" :src="'https://d2hyqud1qblstw.cloudfront.net/dawataTemporary/system/default/dawata.jpg'">
              <div v-if="item.selected" class="checkmark">
                <v-icon color="blue">mdi-check-circle</v-icon>
              </div>
            </v-img>
            <div style="font-size: 16px; padding: 5px 10px; display: flex; justify-content: space-between; align-items: center;">
              {{ item.location_name }}
              <div style="width: 15px; height: 15px; display: flex; justify-content: space-between; align-items: center;">
                <img width="100%" height="100%" :src="item.country.flags.png" alt="">
              </div>
            </div>
          </v-card>
        </div> -->
      <!-- </div> -->
      <div style="position: absolute; bottom: 10px; left: 10px; width: 94%;">
        <v-btn block color="info" @click="triggerFilter">Show Result </v-btn>
        <v-btn x-small block color="error" @click="triggerReset">Reset </v-btn>
      </div>
    </v-sheet>
  </template>
  
  <script>
import moment from 'moment';
  
  export default {
    props:['count'],
    data() {
      return {
        queryInstructor:null,
        filter: [],
        filterShowInstructor:[],
        filterShowStudio:[],
        location:[],
        countries:[],
        selectedInstructors:[],
        selectedCountries:[],
        counted:0,
      };
    },
    watch:{
      count(newval){
        this.counted = newVal
      }
    },
    mounted() {
      this.getSetups();
    },
    methods: {
      triggerReset(){
        this.$emit('reset',{})

      },
      triggerFilter(){
        this.$emit('filterTrigger',{})
      },
      getFiltered(){
        console.log("country", this.selectedCountries)
        console.log("instru", this.selectedInstructors)
         // Filter by selected countries and instructors
        this.$emit('filter',
          {
            countries:this.selectedCountries,
            instructors:this.selectedInstructors
          }
        )
          // Output the filtered data
      },
      toggleSelectedInstructor(name) {
        const index = this.selectedInstructors.indexOf(name);
        if (index !== -1) {
          this.selectedInstructors.splice(index, 1); // Remove if already selected
        } else {
          this.selectedInstructors.push(name); // Add if not already selected
        }
        this.getFiltered()

      },
      isSelectedInstructor(name) {
        return this.selectedInstructors.includes(name);
      },
      toggleSelectedCountry(name) {
        const index = this.selectedCountries.indexOf(name);
        if (index !== -1) {
          this.selectedCountries.splice(index, 1); // Remove if already selected
        } else {
          this.selectedCountries.push(name); // Add if not already selected
        }
        this.getFiltered()
      },
      isSelectedCountry(name) {
        return this.selectedCountries.includes(name);
      },
      toggleSelection(location) {
        location.selected = !location.selected; // Toggle selected property
      },
      querySearch(location){
        if (location == 'instructor') {
          
        }else if(location == 'studio'){

        }
      },
      async getSetups(){
        await this.getInstructor()
        await this.nodeReq('system/filterShowStudio', {}).then((data) => {
          this.locations = data.map((itm) => {
            itm.country = JSON.parse(itm.country);
            itm.selected = false; // Initialize selected property
            return itm;
          });
        });
        await this.fetchCountries()
      },
      getInstructor(){
        this.nodeReq('system/filterShowInstructor', {query: this.queryInstructor}).then((data) => {
          this.filterShowInstructor = data
        });
      },
      async fetchCountries() {
        try {
          const response = await fetch('https://restcountries.com/v3.1/all?fields=name,flags,cca2');
          const data = await response.json();
          console.log("Reckon", data)
          this.countries = data;
        } catch (error) {
          console.error('Error fetching countries:', error);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .selected {
    border: 2px solid blue;
  }
  
  .checkmark {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  </style>
  