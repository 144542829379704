<template>
    <div class="playlist-single">
        <div class="header">
            <div class="header-div">
                <h2>{{ playlist.name}}</h2>
                <span>{{ playlist.videos.length }} {{ playlist.videos.length > 1 ?'Videos':'Video' }}</span>
            </div>
            <!-- <a href="#" class="download-link">Download All<v-icon class="download">mdi-download</v-icon></a> -->
        </div>
        <div v-if="playlist.videos.length != 0" class="list-of-vids">
            <div v-for="(vid, index) in playlist.videos" :key="index" class="vid-container" @click="goToVideo(vid)">
                <v-avatar class="vids-image" :size="90" tile >
                        <v-img :src="vid.thumbnail"></v-img>
                    </v-avatar>
                <div  class="vid-title">
                    <div class="head">
                        <div class="top-tier">
                            <span class="sub-title">{{ vid.cat_name }} | {{ vid.sub_name }}</span>
                            <!-- <a href="#"><v-icon class="download">mdi-download</v-icon></a> -->
                        </div>
                        <h4>{{ vid.title }}</h4>
                        <span class="sub-title">{{ 'instructor' }}</span>
                        </div>
                    <div class="text-container">
                    {{ vid.description }}
                    </div>
                </div>
            </div>
        </div>
        <div class="playlist-no-vid" v-else>
            <p>
                Exciting news! Your playlist is ready to be transformed with engaging videos. Let's spice it up – add videos to take your content to the next level!
            </p>
            <v-btn small @click="$router.push({name:'Home'})"> <v-icon left>mdi-compass</v-icon> Explore Videos</v-btn>
        </div>
    </div>
</template>

<script>
import ListOfVideos from '@/components/ListOfVids.vue'

export default {
    name: 'DawataPlaylistVid',

    data() {
        return {
            playlist:[]
        };
    },

    components:{
        ListOfVideos,
    },
    mounted() {
        if (this.$route.params.id == 'liked') {
            this.getLiked()
        }else{
            this.getPlaylistDetails()
        }
    },

    methods: {
        goToVideo(vid){
            console.log("the", vid)
            this.$router.push({
                name: 'singleVid',
                params: {
                    category: vid.cat_name.toLowerCase(),
                    id:vid.category,
                    video_id: vid.vid_id,
                },
            });        
        },
        getPlaylistDetails(){
            
            this.nodeReq('playlist/get_playlist_video', {
                id: this.$route.params.id,
            }).then((data) => {
              console.log("rommmmmm", data);
              this.playlist = data
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });

            // this.req('p_videos', 'playlist_video', {
            //         id: this.$route.params.id,
            // }).then((data) => {
            //   console.log("rommmmmm", data);
            //   this.playlist = data
            // }).catch((error) => {
            //     // API call failed (optional: you can handle errors if needed)
            // });
        },
        getLiked(){
            this.nodeReq('playlist/get_playlist_liked', {
                    id: this.$route.params.id,
                    user_id:this.$store.state.user_id
            }).then((data) => {
              console.log("rommmmmm", data);
              this.playlist = data
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
            
            // this.req('p_videos', 'playlist_like', {
            //         id: this.$route.params.id,
            //         user_id:this.$store.state.user_id
            // }).then((data) => {
            //   console.log("rommmmmm", data);
            //   this.playlist = data
            // }).catch((error) => {
            //     // API call failed (optional: you can handle errors if needed)
            // });
        }
        
    },
};
</script>

<style lang="scss" scoped>
.playlist-single{
    color:#fff;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 10px;
        .header-div{
            line-height: 22px;
            span{
                font-size: 14px;
            }
        }
        .download-link{
            color: #fff;
            font-size: 14px;
            .download{
                color:#fff
            }
        }
    }
    .playlist-no-vid{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
        padding: 5%;
        text-align: center;
    }
    .list-of-vids{
        padding: 20px;
        .vid-container{
            display: flex;
            align-items: center;
            .vids-image{
                width: 120px;
                height: 90px;
            }
            .vid-title{
                padding: 10px;
                .head{
                    line-height: 18px;
                    margin-bottom: 5px;
                }
                .top-tier{
                    display: flex;
                    justify-content: space-between;
                    .download{
                        color:#fff
                    }
                }
                .sub-title{
                    font-weight: bold;
                    font-size: 12px;
                }
                .text-container {
                    display: -webkit-box; /* This is needed for older versions of Safari */
                    -webkit-line-clamp: 2; /* Number of lines to show */
                    -webkit-box-orient: vertical; /* Set text to flow vertically */
                    overflow: hidden; /* Hide any overflowing content */
                    text-overflow: ellipsis; /* Show ellipsis for truncated content */
                    font-size: 14px;

                }
            }
        }
    }
}


</style>