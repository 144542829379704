import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    isLogin:false,
    user_id:null,
    name:null,
    version:null,
    user_type:'regular',
    device_key:null,
    selectedMains:[
      {
        name:"Main Category",
        options:[]
      },
      {
        name:"Type",
        options:[]
      },
      {
        name:"Instructor",
        options:[]
      },
      {
        name:"Duration",
        options:[]
      },
       
      {
        name:"Difficulty",
        options:[]
      },
      {
        name:"Intensity",
        options:[]
      }
    ],
    category:[],
    instructors:[],
    videos:[]
  },
  getters: {
  },
  mutations: {
    addVideo(state, newVideo) {
      state.videos.push(newVideo);
    },
    loginDataSet(state, data) {
      state.isLogin = true;
      state.user_id = data.id;
      state.name = data.name;
      state.version = data.mobile_version,
      state.user_type = data.user_type
    },
    updateVersion(state, data) {
      state.version = data.mobile_version
    },
    createDeviceKey(state, data) {
      state.device_key = data.key
    },
    categoryUpdate(state, data) {
      state.category = data
    },
    instructorUpdate(state, data){
      state.instructors = data
    },
    videosUpdate(state, data){
      state.videos = data
    },
    resetState(state) {
      state.isLogin = false;
      state.user_id = null;
      state.name = null;
      state.device_key=null;

      state.selectedMains = [
        {
          name: "Main Category",
          options: []
        },
        {
          name: "Type",
          options: []
        },
        {
          name: "Instructor",
          options: []
        },
        {
          name: "Duration",
          options: []
        },
        {
          name: "Difficulty",
          options: []
        },
        {
          name: "Intensity",
          options: []
        }
      ];
  }
},
  actions: {
    saveVideo(context, videoData) {
      // You can perform additional processing here before saving the video
      context.commit('addVideo', videoData);
    },
    saveLoginData(context, data){
      context.commit('loginDataSet', data);
    },
    updateToCurrentVersion(context, data){
      context.commit('updateVersion', data);
    },
    registerDeviceKey(context, data) {
      context.commit('createDeviceKey', data);
    },
    actionCategoryUpdate(context, data) {
      context.commit('categoryUpdate', data);
    },
    actionVideoUpdate(context, data) {
      context.commit('videosUpdate', data);
    },
    actionInstructorUpdate(context, data) {
      console.log("roafer inst", data)
      context.commit('instructorUpdate', data);
    },
    
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]

})
