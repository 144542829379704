<template>
    <v-container>
      <!-- Conditional rendering based on OTP validation -->
      <v-row v-if="!otpValidated" style="color: #fff; padding: 20px;">
        <v-col cols="12">
          <h2 class="header">CHANGE PASSWORD</h2>
          <p></p>
        </v-col>
        <v-col cols="12">
          <h3 class="subheader">We sent you an OTP on your email {{ $route.params.email }}</h3>
        </v-col>
        <v-col cols="12">
          <v-text-field
          dark
            label="Enter 4 digit OTP"
            type="number"
            maxlength="4"
            v-model="otp"
            outlined
          ></v-text-field>
        </v-col>
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn color="error" @click="onCancel">Cancel</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="validateOTP">Proceed</v-btn>
          </v-col>
        </v-row>
      </v-row>
  
      <!-- New Password Fields -->
      <v-form  v-else ref="formRef" v-model="formValid" style="color: #fff; padding: 20px;">

      <v-row>
        <v-col cols="12">
          <h2 class="header">CHANGE PASSWORD</h2>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="New Password"
            type="password"
            dark
            v-model="newPassword"
            :rules="passwordRules"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Confirm New Password"
            type="password"
            dark
            v-model="confirmPassword"
            :rules="confirmPasswordRules"
            outlined
          ></v-text-field>
        </v-col>
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn color="error" @click="onCancel">Cancel</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="saveNewPassword">Save New Password</v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
      <Loading :isLoading="loading"/>

    </v-container>
  </template>
  
  <script>
import Loading from '@/components/spinner.vue'
import moment from 'moment';

export default {
    components:{
      Loading
    },
    data() {
      return {
        otp: '',
        otpValidated: false,
        newPassword: '',
        confirmPassword: '',
        loading:false,
        user_id:null,
        formValid:false
      };
    },
    methods: {
      onCancel() {
        // Handle cancel action
        console.log('Cancel clicked');
        // Reset the form
        this.otp = '';
        this.otpValidated = false;
        this.newPassword = '';
        this.confirmPassword = '';
      },
      validateOTP() {
        // Validate the OTP (for demonstration purposes, assume the OTP is '1234')
        this.loading = true
        this.nodeReq('user/verifyFPOtp',{otp: this.otp, email:this.$route.params.email}).then((data)=>{
          if (data.isValid) {
            this.user_id = data.user_id
            this.otpValidated = true;
            this.loading = false

          } else {
            this.loading = false
            alert('Invalid OTP. Please try again.');
          }
        }).catch((error)=>{
            console.log("mores", error)
        })
       
      },
      saveNewPassword() {
        if (!this.$refs.formRef.validate()) {
                    return false
                }
        this.loading = true; // Start loading
                
          this.req('p_user','update_password_user',{id:this.user_id,password:this.newPassword}).then((data)=>{
              if (data) {
                  this.successPage = true; // Show success page
                  this.login({email:this.$route.params.email, password:this.newPassword})
              }
          })

      },
      login(extra){
        this.req('p_user','login',extra).then(async (data)=>{
                const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');

                await this.nodeReq('devices/remove_keys',{ user_id:data.id}).then((data)=>{

                }).catch((error)=>{
                    console.log("mores", error)
                })
                var deviceReg = {
                    device_name: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
                    user_agent:navigator.userAgent,
                    user_id:data.id,
                    timestamp:timestamp
                }
                await this.nodeReq('devices/register',deviceReg).then(async (data)=>{
                    this.$store.dispatch('registerDeviceKey', data);

                }).catch((error)=>{
                    console.log("mores", error)
                })
                

                this.$store.dispatch('saveLoginData', data);

                this.$router.push({name:'Home'})
            }).catch((error)=>{
                console.log("mores", error)
                alert(error)
                this.loading = false

            })
      }
    },
    computed: {
      passwordRules() {
        return [
          v => !!v || 'Password is required',
          v => (v && v.length >= 6) || 'Password must be at least 6 characters',
        ];
      },
      confirmPasswordRules() {
        return [
          v => !!v || 'Confirm password is required',
          v => v === this.newPassword || 'Passwords do not match',
        ];
      },
    },
  };
  </script>
  
  <style scoped>
  .header {
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  
  .subheader {
    text-align: left;
  }
  </style>
  