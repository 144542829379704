<template>
    <div class="profile">
        <!-- <v-card elevation="3" class="pa-3 profile-card">
            <v-avatar size="70"><img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-avatar>
            <span>ENRICKE JANU MORALES</span>
        </v-card> -->
        <div class="text-center">
            <v-avatar size="100"><v-img :src="'https://d2hyqud1qblstw.cloudfront.net/dawataTemporary/system/default/dawata.jpg'"></v-img></v-avatar>
            <h3 class="mt-3">{{ name }}</h3>
            <v-btn class="mt-1" small color="primary">Edit Profile</v-btn>
        </div>
        <v-tabs show-arrows mobile class="mt-3" v-model="tab" align-tabs="center" color="deep-purple-accent-4" dark background-color="transparent">
            <v-tab >Info</v-tab>
            <v-tab >Transaction</v-tab>
            <v-tab >Settings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" >
                <v-tab-item >
                    <div class="profiles-div">
                        <v-card flat  class="pa-3 mt-4" style="background-color: transparent !important;;">
                            <v-card-title>Personal Information</v-card-title>
                            <!-- <v-btn class="mb-4" small color="primary">Update Information</v-btn> -->

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Fullname</v-list-item-subtitle>
                                    <v-list-item-title>{{name}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                                    <v-list-item-title>{{email}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>

                        <v-card elevation="3" class="pa-3 mt-4">
                            <v-card-title>Payment Methods</v-card-title>
                            <v-btn class="mb-4" small color="primary" @click="openPaymentMethodDialog">Update Payment Methods</v-btn>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>GCASH</v-list-item-subtitle>
                                    <v-list-item-title>
                                        <v-chip small :color=" pm[0].acc_name  ? 'primary':'error'">{{ pm[0].acc_number ? pm[0].acc_number:'Add GCASH Number' }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>MAYA</v-list-item-subtitle>
                                    <v-list-item-title>
                                        <v-chip small :color=" pm[1].acc_name  ? 'primary':'error'">{{ pm[1].acc_number ? pm[1].acc_number:'Add MAYA Number' }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Grabpay</v-list-item-subtitle>
                                    <v-list-item-title>
                                        <v-chip small :color=" pm[2].acc_name  ? 'primary':'error'">{{ pm[2].acc_number ? pm[2].acc_number:'Add MAYA Number' }}</v-chip>
                                    </v-list-item-title>                   
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Card</v-list-item-subtitle>
                                        <v-list-item-title>
                                            <v-chip small :color=" pm[3].acc_name  ? 'primary':'error'">{{ pm[3].acc_name ? pm[3].acc_name:'Add Card Billing Address' }}</v-chip>
                                        </v-list-item-title>         
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    
                </v-tab-item>
                <v-tab-item>
                    <v-card elevation="3" class="pa-3 mt-4">
                        <v-card-title>DANGER ZONE</v-card-title>
                        <p>
                            Caution: Once you hit delete, there's no turning back! It's like sending a message in a bottle - once it's in the ocean, it's gone forever!
                        </p>
                        <v-btn @click="deleteAccount" color="red" style="color: white">Delete Account</v-btn>
                    </v-card>
                </v-tab-item>
        </v-tabs-items>
       

       <v-bottom-sheet v-model="informationDialog"  persistent fullscreen>
            <v-sheet class="waiting-area" style="height: 100%; position: relative; padding: 0px;">
            </v-sheet> 
        </v-bottom-sheet>
        <v-bottom-sheet v-model="paymentMethodDialog"  persistent fullscreen>
            <v-sheet class="" style="height: 100%; position: relative; padding: 10px; ">
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <h4>Payment Method</h4>
                    <v-btn @click="paymentMethodDialog =false" x-small color="error">CLOSE</v-btn>
                </div>
                <div style="max-height: 90vh; overflow: auto; padding: 20px">
                    <v-expansion-panels focusable>
                    <v-expansion-panel >
                        <v-expansion-panel-header>GCASH</v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-2 paymentClass">
                            <v-text-field dense label="Name"  v-model="pm[0].acc_name" placeholder="Name" outlined></v-text-field>
                            <v-text-field label="GCASH" dense v-model="pm[0].acc_number" placeholder="Number" outlined></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>MAYA</v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-text-field dense label="Name" v-model="pm[1].acc_name" placeholder="Name" outlined></v-text-field>
                            <v-text-field label="MAYA" dense v-model="pm[1].acc_number" placeholder="Number" outlined></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>GRABPAY</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field dense label="Name" v-model="pm[2].acc_name" placeholder="Name" outlined></v-text-field>
                            <v-text-field label="GRABPAY" dense v-model="pm[2].acc_number" placeholder="Number" outlined></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>CARD</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field dense label="Name" v-model="pm[3].acc_name" placeholder="Placeholder" outlined></v-text-field>
                            <v-text-field v-model="pm[3].line1" dense label="Address Line1" placeholder="Placeholder" outlined></v-text-field>
                            <v-text-field v-model="pm[3].line2" dense label="Address Line2" placeholder="Placeholder" outlined></v-text-field>
                            <v-text-field v-model="pm[3].city" dense label="City" placeholder="Placeholder" outlined></v-text-field>
                            <v-text-field v-model="pm[3].state" dense label="State" placeholder="Placeholder" outlined></v-text-field>
                            <v-text-field v-model="pm[3].postal_code" dense label="Postal/Zip Code" placeholder="Placeholder" outlined></v-text-field>
                            <CountrySelector @country_selected="country_selected" :countryProp="country"/>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div >
                    <v-btn @click="savePayment" color="primary" block>SAVE Changes</v-btn>
                </div>
                </div>
            </v-sheet>
        </v-bottom-sheet>
        
    </div>
</template>

<script>
import CountrySelector from '@/components/countrySelector.vue'

    export default {
        components:{
            CountrySelector
        },
        data(){
            return{
                tab:null,
                country:null,
                name:null,
                email:null,
                pm:[
                    {
                        name:'gcash',
                        acc_name:null,
                        acc_number:null
                    },
                    {
                        name:'maya',
                        acc_name:null,
                        acc_number:null

                    },
                    {
                        name:'grabpay',
                        acc_name:null,
                        acc_number:null

                    },
                    {
                        name:'card',
                        acc_name:null,
                        line1:null,
                        line2:null,
                        city:null,
                        state:null,
                        postal_code:null,
                        country:null,
                    },
                ],
                informationDialog:false,
                paymentMethodDialog:false,
            }
        },
        mounted(){
            this.getAccountProfile()
        },
        methods:{
            country_selected(){
                this.pm[3].country = this.country
            },
            openPaymentMethodDialog(){
                this.paymentMethodDialog =true
            },
            openInfoDialog(){
                this.informationDialog =true
            },
            deleteAccount(){
                this.req('p_setup','delete_account',{id:this.$store.state.user_id}).then((data)=>{
                    this.$store.commit('resetState')
                    this.$router.push({name:"Home"});
                });
            },
            savePayment(){
                this.nodeReq('user/payment_methods/',{billing:this.pm,id:this.$store.state.user_id}).then((data)=>{
                   this.getAccountProfile()
                   this.paymentMethodDialog = false

                });
            },
            getAccountProfile(){
                this.nodeReq('user/get_user_info/',{id:this.$store.state.user_id}).then((data)=>{
                   console.log("data", data)
                    this.name = data.name
                    this.email = data.email
                    this.pm = data.payment_methods ? JSON.parse( data.payment_methods): [
                    {
                        name:'gcash',
                        acc_name:null,
                        acc_number:null
                    },
                    {
                        name:'maya',
                        acc_name:null,
                        acc_number:null

                    },
                    {
                        name:'grabpay',
                        acc_name:null,
                        acc_number:null

                    },
                    {
                        name:'card',
                        acc_name:null,
                        line1:null,
                        line2:null,
                        city:null,
                        state:null,
                        postal_code:null,
                        country:null,
                    },
                ]
                });
            }
        }
    }
</script>



<style lang="scss" >

</style>

<style lang="scss" scoped>
@import './profile.scss';
    .profile{
        display: flex;
        flex-direction: column;
        color: white;
        padding:10px;
        height: 100%;

        p{
            font-style: italic;

        }
        .profile-card{
            background: rgba(33, 33, 33, 0.342);
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            backdrop-filter: blur( 5.5px );
            -webkit-backdrop-filter: blur( 5.5px );
            border-radius: 10px;
            border: 1px solid rgba(60, 60, 60, 0.18);
        }
        .profiles-div{
            max-height: 87vh;
            overflow: auto;
        }

        .paymentClass{
            padding: 10px!important;

        }

       
    }
</style>