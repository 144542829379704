<template>
    <div class="cat_vids">
        <div class="header">
            
            <h2>{{ $route.params.category.toUpperCase() }}</h2>
        </div>
        <div class="sec-header"> 
            <h5>Showing {{ vid_count }} {{ vid_count >1 ?'Videos' :'Video'}}</h5>
            <v-btn @click="openThyFilter()"  color="#fff"  elevation="2"  outlined  x-small>FILTER</v-btn>
        </div>
        <div class="third-header"> 
            <div class="input-holder">
                <h5>Sort By: </h5>  
                <v-select @change="getVideos(filterNew)" class="j-select" :items="sorts" v-model="sortedVid" label="Sort" :height="20" solo dense hide-details></v-select>
            </div>
            <div class="btn-actions">
                <!-- <v-btn  color="#fff" icon elevation="2" x-small><v-icon>mdi-grid-large</v-icon></v-btn>
                <v-btn  color="#fff" icon elevation="2" x-small><v-icon>mdi-menu</v-icon></v-btn> -->
            </div>
        </div>
        <div class="list-container">
            <ListOfVideos v-if="videos.length !=  0" :videos="videos" @selectVid="selectVideo"/>
            <NoVideo v-else/>
        </div>
    </div>
</template>

<script>
import ListOfVideos from '@/components/ListOfVids.vue'
import NoVideo from '@/components/no_videos.vue'
export default {
    name: 'DawataAppCategoryVideo',
    components:{
        ListOfVideos,
        NoVideo
    },
    data() {
        return {
            videos:[],
            vid_count:0,
            sorts:["Newest","Oldest"],
            sortedVid:"Newest",
            filterNew:[],
            originalFilter:[
                {
                name:"Type",
                options:[]
                },
                {
                name:"Instructor",
                options:[]
                },
                {
                name:"Duration",
                options:[0,299]
                },
                
                {
                name:"Difficulty",
                options:[
                    {
                    id:0,
                    name:"Beginner",
                    selected:false,
                    },
                    {
                    id:1,
                    name:"Intermediate",
                    selected:false,
                    },
                    {
                    id:2,
                    name:"Moderate",
                    selected:false,
                    },
                    {
                    id:3,
                    name:"Advanced",
                    selected:false,
                    }
                ]
                },
                {
                    name:"Intensity",
                    options:[
                        {
                        id:0,
                        name:"1",
                        selected:false,
                        },
                        {
                        id:1,
                        name:"2",
                        selected:false,

                        },
                        {
                        id:2,
                        name:"3",
                        selected:false,

                        },
                        {
                        id:3,
                        name:"4",
                        selected:false,

                        }
                    ]
                }
            ]
        };
    },
    

    mounted() {
         this.$bus.$on('change_vids', (eventData) => {
            console.log("eventdata from catcart", eventData)
            this.filterNew = eventData
            this.getVideos(eventData)
        })
        // this.$bus.$on('new_video', (eventData) => {
           
        //     this.getVideos(this.filterNew)
        // })

        this.$bus.$on('changeVideo', (eventData) => {
            console.log("how come", eventData)
            
        })

        
       this.setup()
    },

    methods: {
        async setup(){
            await this.getSubCats()
            await this.getInstructors()
            var newFilter = JSON.parse(JSON.stringify(this.originalFilter))
            // newFilter.unshift({
            //     name:"Main Category",
            //     options:[
            //         {
            //             name:this.$route.params.category,
            //             id:parseInt(this.$route.params.id)
            //         }
            //     ]
            // })
            console.log("sefaeas",newFilter);
            this.filterNew = newFilter

            await this.getVideos(newFilter)
        },
        getSubCats(){
            this.req('p_setup','get_sub_category',{id:this.$route.params.id}).then((data)=>{
                this.originalFilter[0].options = data.map((itm)=>{
                    itm.selected = false
                    return itm
                })
            }).catch((err)=>{
                console.log("huh", err)
            });
        },
        getInstructors(){
            this.req('p_setup','m_get_instructor',{}).then((data)=>{
                console.log("ratter", data);
                this.originalFilter[1].options = data.map((itm)=>{
                    itm.id = itm.instructor_id
                    itm.selected = false
                    return itm
                })
            }).catch((err)=>{
                console.log("huh", err)
            });
        },
        refreshPage() {
            window.location.reload(); // Refresh the page
        },
        openThyFilter(){
            console.log("pass original filter",this.originalFilter)
            this.$bus.$emit('openFilter', this.originalFilter)
        },
        getVideos(data){
            console.log("wanknkn", this.sortedVid)
            this.$bus.$emit('change_loading', true)
            this.videos = []
             this.req('p_videos','get_vids',{filter:data, sort:this.sortedVid}).then((data)=>{
                this.videos = data
                this.vid_count = data.length
                console.log("videos wal",data)
                this.$bus.$emit('change_loading', false)
            });
            // this.nodeReq('videos/get_videos/',{filter:data, sort:this.sortedVid}).then((data)=>{
            //     this.videos = data
            //     this.vid_count = data.length
            //     console.log("videos wal",data)
            //     this.$bus.$emit('change_loading', false)
            // });

        },
        selectVideo(video){
            this.videos = video
            console.log("this.$route.params.category,", video.vid_id)
            this.$router.push({
                name: 'singleVid',
                params: {
                    category: this.$route.params.category,
                    video_id: video.vid_id,
                },
            });            // this.setupVideoPlayer(video)
        },
    },
};
</script>

<style lang="scss">
 .j-select{
        .v-input__control{
            min-height: 25px!important;
        }
    }  

</style>

<style lang="scss" scoped>

.cat_vids{
    font-family: 'Andada Pro', serif;

}
.header{
    text-align: center;
    color:#fff;
    h2{
        color:#81593F;
    }
}
.sec-header{
    color:#fff;
    padding: 10px 15px 0px;
    display: flex;
    justify-content: space-between;
   
}

.third-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 15px;
    color:#fff;
    .input-holder{
        width: 300px;
        display:flex;
        align-items: center;
        h5{
            margin-right: 10px;
        }
        .j-select{
            width: 50%;
        }
    }
}

.list-container{
    max-height: 77vh;
    overflow: auto;
    padding: 20px;
}
</style>