<template>
    <div class="login">
        <Loading :isLoading="loading"/>

        <div class="login-div">
            <img class="log0-dawata" :src="require('@/assets/dawatalogin.png')" alt="">
            <v-form ref="loginform" v-model="valid" class="form-login">
                <v-text-field
                    dark
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    color="#fff"
                    outlined
                    required
                    autocomplete="null"
                    style="margin-bottom: 10px;"
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                    label="Password"
                    color="#fff"
                    outlined
                    dark
                    autocomplete="null"
                    required
                ></v-text-field>
            </v-form>
            <div class="login-action">
                <v-btn  color="#81593F" class="mb-3" dark block @click="login()">LOGIN</v-btn>
                <h5 style="color:#fff">No Account Yet ? <a href="/register" >Create Account</a></h5>
                <button style="color: #1976d2;" @click="triggerFp()">Forgot Password</button><br>
                <a href="javascript:void(0)" class="mt-5" @click.prevent="$router.push({name:'Home'})">Explore</a>
                
                <!-- <v-btn small color="#81593F" class="mb-3" dark block @click="$router.push({name:'Home'})">EXPLORE</v-btn> -->
            </div>
            <div class="footer-email">
                <span> {{osData +" - "+appVersion}}</span>
            </div>
           
        </div>

        <v-dialog v-model="forgotPassword" v-if="forgotPassword" width="450px">
            <v-card>
                <div style="background-color: #172b23; color: #fff;">
                    <v-card-title>Forgotten Password</v-card-title>
                    <v-card-subtitle>The Guide will send you an email for your password retrieval</v-card-subtitle>
                </div>
                <div class="pa-5">
                    <v-alert v-if="emailHasError" dark color="error">Ohh the email is not registered to us.</v-alert>
                    <v-text-field v-if="!email_sent" outlined v-model="forgertEmail" label="Email"></v-text-field>
                    <p v-else class="text-center">An email with the reset password has been sent to your email</p>
                </div>
                <v-card-actions>
                     <v-btn v-if="!email_sent" block @click="sendForgotPassword" color="success">Change Password</v-btn>
                    <v-btn v-else color="error" block  @click="forgotPassword = false; forgertEmail = null; email_sent=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <JSWAL :status="openSwal" :title="title" :msg="msg" :type="type" @close-dialog="closeDialog"/> -->
    </div>
</template>

<script>
import Loading from '@/components/spinner.vue'
import moment from 'moment';

export default {
    name: 'DawataLogin',
    components:{
        Loading
    },
    data() {
        return {
            valid:false,
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            loading:false,
            forgotPassword:false,
            email_sent:false,
            forgertEmail:null,
            openSwal:false,
            title:null,
            msg:null,
            type:null,
            emailHasError:false,
            appVersion: '--',
            osData:'--'
        };
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {

          if (vm.$store.state.isLogin) {
            vm.$router.push({name:"Home"})
          }else {
              next();
          }
        });
    },

    mounted() {
        if (window.appVersion) {
            this.appVersion = window.appVersion;
            this.osData = window.appOS;
        }
    },

    methods: {
        triggerFp(){
            this.forgotPassword = true
        },
        sendForgotPassword(){
                this.loading = true
                this.req('p_user','forgot_password',{email:this.forgertEmail}).then((data)=>{
                    if (data == 'Email Have beed sent') {
                        this.loading = false
                        this.email_sent = true
                        this.emailHasError = false
                            this.$router.push({
                                name: 'ForgotPasswordPage',
                                params: { email: this.forgertEmail }
                            });    

                            this.forgertEmail = null

                        // this.forgotPassword = false
                    }else{
                        this.loading = false
                        this.email_sent = false
                        this.forgertEmail = null
                        this.emailHasError = true
                    }
                }).catch((error)=>{
                
                })
            },
         login(){//
            this.loading = true

            if (!this.$refs.loginform.validate()) {
                this.loading = false

                return false
            }
            var extra = {
                email:this.email,
                password:this.password
            }

            this.req('p_user','login',extra).then(async (data)=>{
                const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');

                await this.nodeReq('devices/remove_keys',{ user_id:data.id}).then((data)=>{

                }).catch((error)=>{
                    console.log("mores", error)
                })
                var deviceReg = {
                    device_name: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
                    user_agent:navigator.userAgent,
                    user_id:data.id,
                    timestamp:timestamp
                }
                await this.nodeReq('devices/register',deviceReg).then(async (data)=>{
                    this.$store.dispatch('registerDeviceKey', data);

                }).catch((error)=>{
                    console.log("mores", error)
                })
                
                this.loading = false

                this.$store.dispatch('saveLoginData', data);

                this.$router.push({name:'Home'})
            }).catch((error)=>{
                console.log("mores", error)
                alert(error)
                this.loading = false

            })

        }
    },
};
</script>

<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
// .login{
//     .login-holder{
//         height: 75vh;
       
//         .main-holder{
//             display: flex;
//             justify-content: center;
//             align-items: center;
//            .login-card{
//                 padding: 20px;
//                 background: rgba(149,110,0,0.5);
//                 -webkit-backdrop-filter: blur(1px);
//                 backdrop-filter: blur(1px);
//                 border: 1px solid rgba(149,110,0,0.25);
//            }
//         }
//     }
// }
.login{
    font-family: 'Andada Pro', serif;

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .login-div{
        width: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form-login{
            width: 100%;
            margin-top: 30px;;
        }
        .login-action{
            text-align: center;
            width: 100%;
            margin-top: 10px;
            .login-btn{
                margin-bottom: 15px;
                width: 100%;
            }
        }
    }
    .log0-dawata{
        width: 200px;
        margin-bottom: 20px;
    }
    .footer-email{
        color: #fff;
        display: flex;
        width: 100%;
        position: absolute;
        bottom: 15px;
        padding-left: 15px;
        font-size: 12px;
    }
  
}
</style>