<template>
    <div>
        <mux-player
            playback-id="TUZzCKE02lmIAHXGrISWMAM01n5CHbRYcLmjkIZFfvsZ4"
            metadata-video-title="ss"
            metadata-viewer-user-id="ss"
            accent-color="#FF0000"
      ></mux-player>
      <video src="https://stream.mux.com/TUZzCKE02lmIAHXGrISWMAM01n5CHbRYcLmjkIZFfvsZ4.m3u8"></video>
    </div>
</template>

<script>
import { ZoomMtg } from "@zoom/meetingsdk"
import * as KJUR from 'jsrsasign';
import moment from "moment";
import '@mux/mux-player';

    export default {
        data(){
            return{
                meeting:[]
            }
        },
        created(){
        //    this.setup()
        },
        mounted() {
            window.addEventListener('orientationchange', this.handleOrientationChange);
            // this.addAttrInline()

        },
        beforeDestroy() {
            window.removeEventListener('orientationchange', this.handleOrientationChange);
        },
        methods:{
            addAttrInline(){
                const videos = this.$el.querySelectorAll('video');
                videos.forEach(video => {
                    video.setAttribute('webkit-playsinline', '');
                    video.setAttribute('playsinline', '');
                });
            },
            handleOrientationChange() {
                // Adjust content based on orientation change
                //alert("test")
            },
             setup(){
                 this.createMeeting()
            },
            createMeeting(){
                    this.meeting = this.$route.params.mn_number
                    console.log(this.meeting)
                    ZoomMtg.preLoadWasm()
                    ZoomMtg.prepareWebSDK()
                    ZoomMtg.init({
                        patchJsMedia: true,
                        enableFullHD:true,
                        enableHD:true,
                        disableInvite:true,
                        disableZoomLogo:true,
                        leaveUrl: `${this.mainUrl}/feedback/${this.meeting}`,
                        success: (success) => {
                            const header = { alg: 'HS256', typ: 'JWT' };
                            const iat = Math.round(new Date().getTime() / 1000);
                            const payload = {
                                sdkKey: 'xC9RKHGeQSqG5vZ3hbzXew',
                                appKey: 'xC9RKHGeQSqG5vZ3hbzXew',
                                role: 0,
                                iat: Math.round(new Date().getTime() / 1000) - 30,
                                exp: iat + 60 * 60 * 2,
                                mn:this.meeting,
                                tokenExp:  iat + 60 * 60 * 2
                            };
                            const sHeader = JSON.stringify(header);
                            const sPayload = JSON.stringify(payload);
                            var sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, 'QB3cwF3pwgEVI73k4OKsh8b98RxTiTHw');
                            console.log("meeting uid", this.meeting)
                            ZoomMtg.join({
                            signature: sdkJWT,
                            meetingNumber:this.meeting,
                            passWord: this.$route.params.passcode,
                            userName: this.$store.state.name,
                            sdkKey: "xC9RKHGeQSqG5vZ3hbzXew",
                            success: (success) => {
                                console.log(success)
                               
                            },
                            error: (error) => {
                                console.log(error)
                            }
                            })
                        },
                        error: (error) => {
                            console.log(error)
                        }
                        })
            }
        }
    }
</script>

<style lang="scss" >
#zmmtg-root{
    z-index: 20;
    .mobile-waiting-room .wr-video {
        width: 100%!important;
        height: 100%!important;
        z-index: 1;
        object-fit: contain!important;
        overflow: scroll;

    }

    .mobile-waiting-room{
        height: unset!important;
        overflow: scroll;

    }
    .mobile-waiting-room .wr-customized{
        height: 270px;
    }

}


</style>