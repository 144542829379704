<template>
    <div class="main">
        <Loading :isLoading="loading"/>
        <div class="content">
            <v-avatar   size="150"><img
                src="https://dawata.s3.ap-southeast-2.amazonaws.com/dawataTemporary/system/default/dawata.jpg"
                alt="Dawata Logo"
            >
            </v-avatar>
            <div v-if="$route.params.type == 'success'">
                <h3 >Thank You</h3>
                <p>You just successfully reserved a class.</p>

                <v-btn v-if="$route.params.type == 'success'" @click="goToLiveClasses"  color="#81593F" dark>Continue</v-btn>
            </div>
            <div v-else-if="$route.params.type == 'cancellation'">
                <h3 >Reservation Cancelled</h3>
                <p>Oh sorry to let you go but there are more clsses to explore</p>

                <v-btn  @click="goToLiveClasses('cancellation')"  color="#81593F" dark>Continue</v-btn>
            </div>
            <div v-else>
                <h3 >PAYMENT FAILED</h3>
                <p>Oops something went wrong. Try again later</p>

                <v-btn  @click="goToLiveClasses('cancellation')"  color="#81593F" dark>Go Back</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import Loading from '@/components/spinner.vue'
    export default {
        components:{Loading},
        data(){
            return {
                loading: true
            }
        },
        created(){
            console.log("meko 1", this.$route.params.type)
            if (this.$route.params.type == 'success') {
                this.reserveClass()
            }else if(this.$route.params.type == 'cancellation'){
                this.cancelReservation('')
            }else{
                this.loading= false

            }
        },
        methods:{
            reserveClass(){
                console.log("meko 2", this.$store.state.user_id, this.$store.state.user_id)

                this.nodeReq('mobile/live_classes/update_to_paid',{id:this.$route.params.id,user_id:this.$store.state.user_id}).then((data)=>{
                    console.log("meko 3",data)

                    this.loading = false
                })
            },
            cancelReservation(){
                this.req('p_setup','cancel_reservation',{live_id:this.$route.params.id, user_id:this.$store.state.user_id}).then((data)=>{
                    this.loading = false
                })
            },
            goToLiveClasses(vartype){
                this.$router.push({ name: 'LiveView', params: { id: this.$route.params.id, sheet: vartype == 'cancellation' ? 'close': 'open', type: this.$route.params.type }});
            },

            gotoinstructor(){
                this.$router.push({name:'Instructor'})

            }
        }
    }
</script>

<style lang="scss" scoped>
    .main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .content{
            text-align: center;
            margin-top: 30px;
            font-family: "Andada Pro", serif;
            color: #fff;
            h3{
                color: #81593F;
            }

        }
    }
</style>