<template>
  <div id="app">
    <v-app >

      <div class="container">
        <div class="card">
          <div class="logo-container">
            <img src="https://admin.dawatawellness.com/img/icon.f21764d4.png" alt="Logo" class="logo">
          </div>
          <h1 class="title">Greetings, dear friend.</h1>
          <p class="text">
            We hope this message finds you well.

            As we evolve on our journey, we're embracing change and growth. This version of the app has completed its purpose and is no longer in use. We invite you to join us on the next step of our path:

            Please click here to download our new app, which offers an enhanced experience for your practice.
            Once you've installed the new app, we kindly ask you to delete this older version, allowing space for new beginnings.

            We're deeply grateful for your presence in our community and look forward to continuing our journey together in the new app.

            Namaste 🙏 
          </p>
          <button class="update-button" @click="openAppStore">UPDATE NOW</button>
        </div>
      </div>
        
         
    </v-app>
    
  </div>
</template>
<script>
  import PullTo from 'vue-pull-to'
  import VersionChecker from  './components/versionChecker'

  export default {
    components: {
      PullTo,VersionChecker
    },
    data: () => ({
      priceRate:null,
      quoteList: [
        "Inhale the future, exhale the past.",
        "Yoga is the journey of the self, through the self, to the self. - The Bhagavad Gita",
        "The body benefits from movement, and the mind benefits from stillness.",
        "Yoga is the practice of quieting the mind.",
        "Yoga is the art of listening to your body."
      ],
      currentQuoteIndex: 0,
      animationDuration: "5s",
      drawer: false,
      group: null,
      title:"Dawata",
      filterBottomModal:false,
      panel: [],
      isLogin:false,
      items: 5,
      duration:[0, 20],
      mulipleOpts:false,
      showLoading:true,
      channel:null,
      defaultCategories:[
                {
                    name:"Instructors",
                    id:998,
                    image:'https://d2hyqud1qblstw.cloudfront.net/system/instructor_img.webp',
                    count:1
                },
                {
                    name:"Live Classes",
                    id:999,
                    image:'https://d2hyqud1qblstw.cloudfront.net/system/live_img.webp',
                    count:1
                },
            ],
      selectedOptions: [
        {
          name:"Main Category",
          options:[]
        },
        {
          name:"Type",
          options:[]
        },
        {
          name:"Instructor",
          options:[]
        },
        {
          name:"Duration",
          options:[0,299]
        },
         
        {
          name:"Difficulty",
          options:[]
        },
        {
          name:"Intensity",
          options:[]
        }
      ],
      originalFilter:[],
      routerName:'',
      updateModal:false,
      advisory:true,
      versionNumber:null
    }),
    computed: {
      advisoryModal(){
        return () => {
          if (!this.updateModal) {
            return true
          }
        }
      },
      getCatmain() {
        return () => {
          console.log("array1", this.originalFilter[0].options, this.$store.state.selectedMains[0].options)
          if(this.$store.state.selectedMains[0].options.length != 0){
            let filteredArray = this.originalFilter[0].options.filter((item1) =>
              this.$store.state.selectedMains[0].options.some((item2) => item1.id === item2.id)
            );
            console.log(filteredArray,'mao nannssss');
            return filteredArray
          }else{
            return this.originalFilter[0].options
          }
        };
      },
      currentQuote() {
        return this.quoteList[this.currentQuoteIndex];
      },
      getFilteredOptions() {
        return (id) => {
          console.log("array1 type", this.originalFilter[1].options, this.$store.state.selectedMains[0].options)
          let filteredArray =  this.originalFilter[1].options.filter((item) => item.cat_id == id);
          return filteredArray
        };
      },
    },
    mounted(){
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }
      console.log("yow", this.$route.name);
      setInterval(this.changeQuote, 50000);
      setTimeout(() => {
        this.showLoading = false
      }, 3000);
      this.$bus.$on('change_loading', (eventData) => {
        this.showLoading = eventData
      })

     
      this.$bus.$on('updateModal', (eventData) => {
        if (!eventData.isOn) {
              this.updateModal = false
            }else{
              this.updateModal = true
            }
        })

      this.channel = this.getPusher().subscribe('dawata-channel')
      console.log("ko", this.channel)
      var self = this
      this.channel.bind('App\\Events\\Realtime', function({data}) {
        console.log("ssssdfe not", data)
        if(data.type == 'new_live'){
          console.log("yeah moreslll", data)
          self.$bus.$emit('new_live',data)
        }else if(data.type == 'new_category'){
          self.$bus.$emit('new_category',data)
        }else if(data.type == 'live_update'){
          self.$bus.$emit('new_live',data)
        }else if(data.type == 'new_instructor'){
          self.$bus.$emit('new_instructor',data)
        }else if(data.type == 'live_start'){
          self.$bus.$emit('live_start',data)
        }else if(data.type == 'live_end'){
          self.$bus.$emit('live_end',data)
        }else if(data.type == 'new_video'){
          self.$bus.$emit('new_video',data)
        }
      })
      this.$bus.$on('openFilter', (eventData) => {
        console.log();
        if (this.$route.name == "Category_view") {
          this.mulipleOpts = false
          var newFilter = JSON.parse(JSON.stringify(eventData))
          newFilter.unshift({
              name:"Main Category",
              options:[
                  {
                      name:this.$route.params.category,
                      id:parseInt(this.$route.params.id),
                      selected:true
                  }
              ]
          })
        }

        if (this.$route.name == "InstructorView") {
          this.mulipleOpts = true
          var newFilter = JSON.parse(JSON.stringify(eventData))
          newFilter.splice(2, 0,{
              name:"Instructor",
              options:[
                  {
                      name:"test",
                      id:parseInt(this.$route.params.id),
                      selected:true
                  }
              ]
          })
        }
        this.originalFilter = eventData
        const filteredFilter = newFilter.map(outerObj => {
          if (outerObj.name === "Duration") {
            // For "Duration" outer object, don't filter its inner objects
            return outerObj;
          }
          // Filter the inner array for each outer object
          const filteredItems = outerObj.options.filter(innerObj => innerObj.selected === true);

          // Return the outer object with the filtered inner array
          return {
            ...outerObj,
            options: filteredItems
          };
        });
        console.log("wokr", filteredFilter);
        this.$store.state.selectedMains= filteredFilter;
        this.openFilter()
      })

      if (this.$route.name == "Login" || this.$route.name == 'Register') {
        this.isLogin = true
      }else{
        this.isLogin = false
      }

      if (this.$store.state.isLogin) {
        this.checkLogin()
      }
        this.$bus.$on('new_category', (eventData) => {
            console.log("nexon",eventData)
           this.requestUpdateCategory();
        })
        this.$bus.$on('new_instructor', (eventData) => {
           this.updateInstructor();
        })

        this.updateInstructor();
        this.requestUpdateCategory();

        this.nodeReq('test/muxCreateLiveStream')

    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    methods:{

      //getters
      openAppStore() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let storeLink;

        // Check if the device is Android
        if (/android/i.test(userAgent)) {
          storeLink = 'https://play.google.com/store/apps/details?id=com.dawata.wellness&pcampaignid=web_share';
        } 
        // Check if the device is iOS
        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          storeLink = 'https://apps.apple.com/us/app/dawata-wellness/id1620510332';
        } 
        // For other devices, you might want to provide a fallback or show a message
        else {
          alert('Please visit our website to download the app for your device.');
          return;
        }

        // Open the appropriate store link
        window.open(storeLink, '_blank');
      },
      async requestUpdateCategory(){
            const data = await this.nodeReq('category/getCategory/')
            this.$store.dispatch('actionCategoryUpdate', this.defaultCategories.concat(data));

        },
      updateInstructor(){
          this.nodeReq('instructor/get_instructor/',{}).then((data)=>{
              // this.instructor = data
              this.$store.dispatch('actionInstructorUpdate', data);
          });
      },

      signOut(){
        this.$store.commit('resetState')
        this.$router.push({name:'Login'})

      },
      goToLogin(){
        this.$router.push({name:"Login"})
      },
      goToRegister(){
        this.$router.push({name:"Register"})
      },
      checkLogin(){
        this.nodeReq('devices/check_active_key/',{key:this.$store.state.device_key}).then((data)=>{
          if (data == 'logout') {
            this.$store.commit('resetState')
            this.$router.push({name:'Login'})
          }
        }).catch((err)=>{
            console.log("huh", err)
        });
       
      },
      goToPlaylist(){
        if (this.$store.state.isLogin) {
          this.$router.push({name:'Playlist'})
        }else{
          this.$router.push({name:'Register'})
        }
      },
      changeQuote() {
        this.currentQuoteIndex = (this.currentQuoteIndex + 1) % this.quoteList.length;
      },
      goBack() {
        this.showLoading = true

        this.$router.go(-1); // This navigates back to the previous route
        this.showLoading = false
      },
      addDurationFilter(){
        const durationIndex = this.originalFilter.findIndex(filter => filter.name === "Duration");
        const onStatemain = this.$store.state.selectedMains.findIndex(filter => filter.name === "Duration");
        this.$store.state.selectedMains[onStatemain].options = this.originalFilter[durationIndex].options

      },
      applyFilter(){
       console.log("fiter",this.$store.state.selectedMains)
       this.filterBottomModal = false
       this.$bus.$emit('change_vids', this.$store.state.selectedMains)
      },
   
      openFilter(){
        this.filterBottomModal = true
      },
      getTypeSelected(index,cat_id){
        const indexType = this.$store.state.selectedMains[index].options.findIndex((item) => item.cat_id === option.cat_id);
        console.log("blue index", indexType);
        return indexType == -1 ? false : true
      },
      toggleOptionSelection(category_name, option) {
        console.log("NUKER", option);
        option.selected = !option.selected;
        let indexContent = this.$store.state.selectedMains.findIndex((item) => item.name === category_name);
        console.log("optionss", this.$store.state.selectedMains[indexContent].options, option, category_name, indexContent);

        const index = this.$store.state.selectedMains[indexContent].options.findIndex((item) => item.id === option.id);
        console.log("index", index);

        if (index !== -1) {
          this.$store.state.selectedMains[indexContent].options.splice(index, 1);
        }else{
          this.$store.state.selectedMains[indexContent].options.push(option);
        }
        console.log("mc",this.$store.state.selectedMains)
      },
    }
  }
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1e1e1e;
  padding: 20px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-container {
  background-color: #1e392a;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

.logo {
  width: 40px;
  height: 40px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.text {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 20px;
}

.update-button {
  background-color: #5e5eff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-button:hover {
  background-color: #4b4bff;
}
</style>