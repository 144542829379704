<template>
    <div>
        <SingleVideo v-if="load" :videos="videos"/>
    </div>
</template>

<script>
import SingleVideo from '@/components/singleViewVideo.vue'

export default {
    name: 'DawataAppSingleVideo',
    components:{
        SingleVideo,
    },
    data() {
        return {
            videos:null,
            load:false
        };
    },

    mounted() {
        console.log("this.$route.params.ccccs,", this.$route.params)
        // this.videos = this.$store.state.videos.find((video) => video.vid_id === this.$route.params.video_id);
        // console.log("this.video view",this.videos)
        this.getVideo()
    },

    methods: {
        getVideo(){
            this.load =true
            this.req('p_videos','get_single_vid',{id:this.$route.params.video_id,user_id:this.$store.state.user_id}).then((data)=>{
                this.videos = data
                console.log("nien", data);
            });
        },
       
    },
};
</script>

<style lang="sass" scoped>

</style>