<template>
    <div class="singleVid-container" >
        <template>
            <div v-if="showfs" class="fullscreen-workaround">
                <div class="fs-view">
                    <div class="vid-fs">
                        <video id="videoref" autoplay playsinline :ref="videoPage" controls controlsList="nodownload nofullscreen" @timeupdate="updateWatchTime">
                            <source :src="singleVid.src" type="video/mp4">
                        </video>
                        
                        <v-btn class="action" x-small @click="showfs = false">Exit Fullscreen</v-btn>
                    </div>
                    
                </div>
               
            </div>

            <template v-else>
                <div  class="video-holder">
                    <video id="videoref" autoplay playsinline :ref="videoPage" controls controlsList="nodownload " @timeupdate="updateWatchTime" :poster="singleVid.thumbnail">
                        <source :src="singleVid.src" type="video/mp4">
                    </video>
                  

                </div>
                <div class="scroller-selected-video">
                    <div class="video-content">
                        <!-- <div class="pa-5">
                            <v-btn x-small @click="showfs=true">Fullscreen</v-btn>
                        </div> -->
                        <div class="title-w-desc">
                            <h4>{{ singleVid.title }}</h4>
                        </div>
                        <div class="view-container">
                            <span class="view-span"> <v-icon class="view-icon" dark>mdi-eye</v-icon> | {{ singleVid.views }}</span>
                            <span class="view-span"> <v-icon class="view-icon" dark>mdi-heart</v-icon> | {{ singleVid.likes }}</span>
                        </div>
                        <div class="playlist-action">
                            <!-- <v-btn   x-small> <v-icon left dark > mdi-download  </v-icon> Download </v-btn> -->
                            <v-btn @click="toggleLike()" v-if="!likeVidId" x-small> <v-icon left dark > mdi-heart-outline  </v-icon> Like </v-btn>
                            <v-btn @click="toggleUnlike()" v-else x-small> <v-icon left dark > mdi-heart  </v-icon> Like </v-btn>
                            <v-btn   x-small @click="showPlaylist()"> <v-icon left dark > mdi-plus-box  </v-icon> Add to playlist </v-btn>
                        </div>
                        <div class="video-desc"> 
                            <div v-if="show_more" class="content-container-more">
                                <p  class="content-more">
                                {{ singleVid.description }}
                                </p>
                            </div>
                            <div v-else class="content-container">
                                <p  class="content">
                                    {{ singleVid.description }}
                                </p>
                            </div>
                            <div>
                                <a href="" @click.prevent="showMoreDesc()"> {{ show_more ?'Show Less':'Show More' }} </a>
                            </div>
                        </div>
                       
                    </div>
                    <div class="related-title">
                        <h4>RELATED VIDEOS</h4>
                    </div>
                    
                    <div class="similar-vids" v-if="videosRelated.length != 0">
                        <ListOfVideos :videos="videosRelated" :isRelatedVideo="true" @changeVideo="gotoRelated"/>
                    </div>
                    <div v-else class="no-related">
                        <v-icon class="icon-n" color="#fff">mdi-video-image</v-icon>
                        <h3>NO VIDEOS</h3>
                    </div>
                </div>
            </template>
        </template>
        <v-bottom-sheet v-model="commentModal" fullscreen>
            <v-sheet
                height="100%"
                
            >
               <div class="commentBox-header" elevation="5">
                <span>Comments</span>
                <a @click="commentModal = false"><v-icon>mdi-close-box</v-icon></a>
               </div>

               <div class="commentList">
                    <div class="commentBox-msg" v-for="(com, index) in comments" :key="index">
                        <div class="header">
                            <v-avatar size="40">
                                <img
                                    :src="com.avatar"
                                    alt="profile-avatar"
                                >
                            </v-avatar>
                            <span class="name">{{com.name}}</span>
                            <span class="date">{{com.created}}</span>

                        </div>
                        <div class="msg">
                            <p>{{ com.msg }}</p>
                        </div>
                    </div>
               </div>
               <div>
                <v-text-field
                        label="Add Comments"
                        solo
                        v-model="comments"
                    ></v-text-field>
               </div>
            </v-sheet>
         </v-bottom-sheet> 
         <v-dialog  v-model="playlistModal" max-width="400">
            <v-card>
                <v-card-title class="text-h6" style="display:flex;justify-content:space-between"> Add to Playlist <a href="#" @click.prevent="playlistModal = false"><v-icon>mdi-close-box</v-icon></a></v-card-title>
                <div v-if="showListPlaylist" style="padding: 20px;">
                    <v-text-field v-model="query" placeholder="Search for the playlist"  clearable dense solo></v-text-field>
                    <v-skeleton-loader
                        v-if="playlistLoading"
                        type="table-heading, list-item-two-line"
                    ></v-skeleton-loader>
                    <div class="list" v-else  style="max-height: 300px; overflow: auto;">
                        <template v-if="playlists.length != 0">
                            <div class="pllist" v-for="(pl, index) in playlists" :key="index" @click="addVideoPlaylist(pl.id)">
                                <v-avatar class="vids-image mb-3" :size="60" tile >
                                    <v-img :src="'https://dawata.s3.ap-southeast-2.amazonaws.com/dawataTemporary/system/default/dawata.jpg'"></v-img>
                                </v-avatar>
                                <div>
                                    <h4>{{ pl.name }}</h4>
                                    <!-- <p>{{ pl.count }} {{ pl.count > 1 ? "Videos" : "Video" }}</p> -->
                                    <p></p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <h5>No Playlist</h5>
                        </template>
                    </div>
                </div>
                <div v-else style="padding: 20px;">
                    <v-text-field v-model="playlist_name" placeholder="Enter the playlist"  clearable dense solo></v-text-field>
                </div>
              
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template >
                        <v-btn
                            v-if="showListPlaylist"
                            color="green darken-1"
                            text
                            @click="triggerCreateView"
                        >
                            Create New
                        </v-btn>
                        <v-btn
                            v-if="!showListPlaylist"
                            color="green darken-1"
                            text
                            @click="CreatePlaylist()"
                        >
                            Save
                        </v-btn>
                        <v-btn
                            v-if="!showListPlaylist"
                            color="green darken-1"
                            text
                            @click="showListPlaylist = true"
                        >
                            Cancel
                        </v-btn>
                    </template>

                  
                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import ListOfVideos from './ListOfVids.vue'
import NoVideos from './no_videos.vue'

export default {
    name: 'DawataAppSingleViewVideo',
    props:["videos"],
    components:{ListOfVideos,NoVideos},
    data() {
        return {
            relatedLoad:false,
            loading:false,
            singleVid:null,
            show_more:false,
            commentModal:false,
            playlistModel:false,
            showListPlaylist:true,
            playlistModal:false,
            apiCallMade:false,
            subCategory:[],
            playlists:[],
            query:null,
            playlistLoading:false,
            showfs:false,
            comments:[
                {
                    name:"John Doe",
                    msg:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt luctus turpis at lacinia. Fusce venenatis bibendum ligula, et viverra nisi varius eu. Integer volutpat augue vitae ligula tincidunt, vitae lacinia nisi pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque dapibus ac neque id tincidunt. Sed rhoncus arcu ac metus ultrices, a fermentum sapien faucibus.",
                    created:"time",
                    avatar:'https://cdn.vuetifyjs.com/images/john.jpg'
                },
                {
                    name:"John Doe",
                    msg:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tincidunt luctus turpis at lacinia. Fusce venenatis bibendum ligula, et viverra nisi varius eu. Integer volutpat augue vitae ligula tincidunt, vitae lacinia nisi pharetra. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque dapibus ac neque id tincidunt. Sed rhoncus arcu ac metus ultrices, a fermentum sapien faucibus.",
                    created:"time",
                    avatar:'https://cdn.vuetifyjs.com/images/john.jpg'
                },
            ],
            contentFilter:[
                {
                    name:"Main Category",
                    items:["Yoga","Meditation","Workshop"]
                },
                {
                    name:"Type",
                    items:['Air Yoga','Yoga 2']
                }
            ],
            filter:[],
            duration: 0, // Will store the total duration of the video
            watchedPercentage: 0, // Will store the percentage of video watched
            watchThreshold: 0.1, // 80% threshold for video watch completion
            isVideoWatched: false,
            likeVidId:null,
            playlist_name:null,
            videosRelated:null,
            video:null,
            videoPage:null
        };
    },
    watch: {
        videos(newValue, oldValue) {
            console.log("roadset", newValue)
            this.loading = true
            this.singleVid = newValue
            this.likeVidId = newValue.likedId
            this.getRelatedVideos()
            setTimeout(() => {
                this.loading = false
            }, 1000);
        },
        query(newValue) {
            // Make your API request here using the newValue
            // For example, use Axios to make an API call to your backend
            this.playlistLoading = true
            console.log("mores");
            this.queryPlaylist(newValue);
        },
        
    },

    mounted() {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        this.setup()
    },

    methods: {
      
       
        handleFullscreenChange() {
        // Check if the video is in fullscreen mode
            const videoElement = document.getElementById('videoref');
            if (document.fullscreenElement === videoElement || document.webkitFullscreenElement === videoElement) {
                // Video entered fullscreen
                
                console.log('Video entered fullscreen');
            } else {
                // Video exited fullscreen
                console.log('Video exited fullscreen');
            }
        },
        enterFullscreen() {
            if (this.video.requestFullscreen) {
                this.video.requestFullscreen();
            } else if (this.video.mozRequestFullScreen) {
                this.video.mozRequestFullScreen();
            } else if (this.video.webkitRequestFullscreen) {
                this.video.webkitRequestFullscreen();
            } else if (this.video.msRequestFullscreen) {
                this.video.msRequestFullscreen();
            }
        },
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        },

        async setup(){
            await this.getPlaylist();
            // await this.getRelatedVideos()
        },
        goToRegister(){
            this.$router.push({name:'Register'})
        },
        gotoRelated(vid){
            console.log("should behere")

            this.$router.push({
                name: 'singleVid',
                params: {
                    category: vid.cat_name.toLowerCase(),
                    id:vid.category,
                    video_id: vid.vid_id,
                },
            });  
        },
        getRelatedVideos(){
            var filter  =  {
                "name": "Main Category",
                "options": [
                    {
                    "name": this.singleVid.cat_name.toLowerCase(),
                    "id": this.singleVid.category
                    }
                ]
            }
            console.log("singleVid",this.singleVid)
             this.req('p_videos','get_vids',{filter:[filter]}).then((data)=>{
                this.videosRelated = []
                data.map((itm)=>{
                    console.log("test", itm.vid_id != this.$route.params.video_id);

                    if (itm.vid_id != this.$route.params.video_id) {
                        this.videosRelated.push(itm);
                    }
                })
                console.log("sssdwad", this.videosRelated);


                this.relatedLoad = true
            });
        },
        queryPlaylist(v){
            this.nodeReq('playlist/query_playlist', {
                query: v,
                user_id:this.$store.state.user_id
            }).then((data) => {
                console.log("pud omo", data)
                this.playlists = data
                this.playlistLoading = false

                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
            // this.req('p_videos', 'query_playlist', {
            //     query: v,
            //     user_id:this.$store.state.user_id
            // }).then((data) => {
            //     console.log("pud omo", data)
            //     this.playlists = data
            //     this.playlistLoading = false

            //     // API call is successful (optional: you can handle success if needed)
            // }).catch((error) => {
            //     // API call failed (optional: you can handle errors if needed)
            // });
        },
        getPlaylist(){
            this.nodeReq('playlist/get_playlist', {user_id: this.$store.state.user_id}).then((data) => {
                this.playlists = data
                this.loading = false
                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
            // this.req('p_videos', 'get_playlist', {user_id: this.$store.state.user_id}).then((data) => {
            //     this.playlists = data
            //     // API call is successful (optional: you can handle success if needed)
            // }).catch((error) => {
            //     // API call failed (optional: you can handle errors if needed)
            // });
        },
        toggleLike(){
            this.req('p_videos', 'like_vid', {
                    id: this.singleVid.vid_id,
                    user_id: this.$store.state.user_id
            }).then((data) => {
                this.likeVidId = data.id
                this.singleVid.likes = data.likeCount
                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });

        },
        toggleUnlike(){
            
            this.req('p_videos', 'unlike_vid', {
                    id: this.likeVidId,
            }).then((data) => {
                this.likeVidId = null
                this.singleVid.likes = data.likeCount
                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                // API call failed (optional: you can handle errors if needed)
            });
        },
        CreatePlaylist(){
            console.log("loggeed",this.playlist_name)
            this.nodeReq('playlist/create_playlist', {
                name: this.playlist_name,
                user_id: this.$store.state.user_id
                }).then( (data) => {
                    this.addVideoPlaylist(data.id)
                    
                }).catch((error) => {
                });
           
        },
        
        addVideoPlaylist(id){
            this.nodeReq('playlist/add_vid_playlist', {
                vid_id: this.singleVid.vid_id,
                id: id
            }).then((data) => {
                console.log("pud omo", data)
                alert('Playlist Added')
                this.playlistModal = false
                // API call is successful (optional: you can handle success if needed)
            }).catch((error) => {
                alert('Video is already on the playlist')

                // API call failed (optional: you can handle errors if needed)
            });
          
        },
        showMoreDesc(){
            this.show_more = !this.show_more
        },
        showPlaylist(){
            this.playlistModal = true
        },
        triggerCreateView(){
            this.showListPlaylist = false
        },
        videoChange(val){
            console.log("opppose", val);
            this.singleVid = val
        },
        updateWatchTime(event) {
        // `event.target` refers to the video element
            const video = event.target;
            this.duration = video.duration;
            const currentTime = video.currentTime;
            this.watchedPercentage = currentTime / this.duration;

            // Check if the user watched 80% of the video and if the API call has not been made yet
            if (this.watchedPercentage >= this.watchThreshold && !this.apiCallMade) {
            this.apiCallMade = true; // Set the flag to true to prevent further API calls

            // Make the API call
                this.req('p_videos', 'add_view', {
                    id: this.singleVid.vid_id,
                    user_id: this.$store.state.user_id
                }).then((data) => {
                    this.singleVid.views = data
                    // API call is successful (optional: you can handle success if needed)
                }).catch((error) => {
                    // API call failed (optional: you can handle errors if needed)
                });
            }
        }
    },
};
</script>

<style lang="scss">
.commentBox-header{
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .no-related{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-n{
            font-size: 60px!important;
        }
    }

    .unlock-desc{
        padding: 20px;
        color: #fff;
        .intructor-holder-title{
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 0px;
            }
        .description{
            padding-top: 10px;
        }
    }

    .pllist{
        gap: 10px;
        display: flex;
        align-items: center;
        .square-container {
        width: 100px; /* Set the width of the square container */
        height: 100px; /* Set the height of the square container */
        display: grid;
        grid-template-columns: repeat(2, 50%); /* Create two columns */
        grid-template-rows: repeat(2, 50%); /* Create two rows */
        }

        .square-image {
        width: 100%; /* Make the image fill its container */
        height: 100%; /* Make the image fill its container */
        object-fit: cover; /* Scale and crop the image to fill the container */
        }
    }

     

    .commentList{
        .commentBox-msg{
            .header{
                display: flex; 
                padding: 10px;
                align-items: center;
                .name{
                    margin:0px 10px
                }
                .date{
                    margin-left: auto;
                }
            }
            .msg{
                padding: 10px;
                p{
                    font-size: 15px;
                }
            }
        }
    }

    .displayList{
        padding: 15px;
        .list{
            display: flex;
            flex-direction: column;
            gap: 10px;
            
        }
        .content{
            padding: 15px;
            color:#fff;
         
        }
    }
</style>

<style lang="scss" scoped>

.singleVid-container{
    color: #fff;
    position: relative;
    .lock-content-holder{
        display: flex;
        align-items: center;
        .lock-icon{
            font-size: 45px;
        }
    }
    .related-title{
        padding: 10px 20px;

    }

    .similar-videos{
          max-height: 77vh;
    overflow: auto;
    padding-top: 10px;
    }
    .scroller-selected-video{
        padding: 220px 20px 20px;
        overflow: auto;
        max-height: 92vh;
    }
    .video-holder{
        width: 100%;
        position: fixed;
        z-index: 5;
        height: 26.60%;
    background-color: #000;
        video{
            width: 100%;
         height: 100%;
        }   
    }
    .video-content{
        padding: 20px;
        color:#fff;

        .content-container {
            max-height: 3.6em; /* Set the maximum height to 3 lines of text */
            line-height: 1.2em; /* Set the line height to control the number of lines */
            overflow: hidden;
            position: relative;
            p{
                font-size: 16px;
            }
        }

        .content-container-more{
            line-height: 1.2em; /* Set the line height to control the number of lines */
            position: relative;
            p{
                font-size: 16px;
            }
        }
        

        .content::before {
            content: "..."; /* Ellipsis */
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 0 5px; /* Adjust as needed */
        }
        .playlist-action{
            display: flex;
            gap:10px;
            padding: 16px 0px;

        }

        .comment-container{
            padding: 15px 0px;
        }

        .view-container{
            display: flex;
            gap: 10px;
            color:#fff;
            .view-span{
                font-size: 14px;
                font-weight: bold;
                .view-icon{
                    font-size: 20px;
                    color: #fff;
                }
            }
        }
       
    }

    .fullscreen-workaround{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 40;
        top: 0;
        .fs-view{
            position: relative;
            top: 0px;
            left: 0px;
            background: black;
            width: 100vw;
            height: 100vh;
            .vid-fs{
                transform: rotate(90deg);
                position: absolute;
                top: 100%;
                right: 0px;
                width: 100vh; /* Fixed width */
                height: 100vw; 
                transform-origin: top right; /* Set the rotation origin to the top right corner */
                video{
                    display: inline-block;
                    vertical-align: baseline;
                    width: 100%;
                    height: 100%;
                }
                .action{
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    z-index: 60;
                }
            }
        }

    }
  
}
</style>