<template>
    <div class="main">
        <Loading :isLoading="loading"/>
        <div class="content">
            <v-avatar   size="150"><img
                src="https://dawata.s3.ap-southeast-2.amazonaws.com/dawataTemporary/system/default/dawata.jpg"
                alt="Dawata Logo"
            >
            </v-avatar>
            <div v-if="$route.params.type == 'success'">
                <h3 >PAYMENT SUCCESS</h3>
                <p>You just reserved a private class. <br> Thank You</p>

                <v-btn v-if="$route.params.type == 'success'" @click="gotoClasses"  color="#81593F" dark>Go To My Class</v-btn>
            </div>
            <div v-else>
                <h3 >PAYMENT FAILED</h3>
                <p>Oops something went wrong. Try again later</p>

                <v-btn  @click="gotoinstructor"  color="#81593F" dark>Go Back</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import Loading from '@/components/spinner.vue'
    export default {
        components:{Loading},
        data(){
            return {
                loading: true
            }
        },
        mounted(){
            if (this.$route.params.type == 'success') {
                this.createMeeting()
            }else{
                this.loading= false

            }
        },
        methods:{
            createMeeting(){
                this.loading= true
                var pid = this.$route.params.pid
                var type = this.$route.params.type
                var duration = this.$route.params.duration
                var extra = {
                    id:pid
                }
                this.req('p_teacher','get_live_info',extra).then((data)=>{
                    console.log("YAWA", data)
                    let parsedDate = moment(data.start, 'YYYY-MM-DD HH:mm');
                    let currentDateTime = parsedDate.format('YYYY-MM-DDTHH:mm:ss');
                    var extra = {
                        duration: duration,
                        start_time:currentDateTime,
                        tpc:data.name,
                        zoom_uid:data.zoom_uid
                    }
                    this.req('p_teacher','create_meeting',extra).then((data_inner)=>{
                       
                        var inner_extra = {
                            mn_number: data_inner.meeting_data.id,
                            passcode: data_inner.meeting_data.password,
                            id:data.live_id,
                            user_id:this.$store.state.user_id
                        }

                        this.req('p_teacher','update_live',inner_extra).then((data_secondary)=>{
                            this.loading= false
                        })
                    })
                })


                //get meeting id 
                //update payment id
            },
            gotoClasses(){
                this.$router.push({name:'MyClasses'})
            },

            gotoinstructor(){
                this.$router.push({name:'Instructor'})

            }
        }
    }
</script>

<style lang="scss" scoped>
    .main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .content{
            text-align: center;
            margin-top: 30px;
            font-family: "Andada Pro", serif;
            color: #fff;
            h3{
                color: #81593F;
            }

        }
    }
</style>