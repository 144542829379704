<template>
    <div class="renderItem">
        <div class="itemContainer" v-for="(events, index) in componentEvents" :key="index" @click="openEvent(events)">
            <template v-if="events.type == 'group_class'">
                <div class="itemImage">
                    <v-img :src="'https://d2hyqud1qblstw.cloudfront.net/system/studio_dawata.webp'"
                            aspect-ratio="16/9"
                            class="white--text align-end category-item"
                            cover
                            width="400px"
                            height="188px"
                            
                            >   
                            </v-img>
                    <div class="itemImageInfo">
                        
                        <v-chip small  color="warning" v-if="events.price && !isfree">{{ events.price }} PHP</v-chip>
                        <v-chip small color="success" v-else>Free</v-chip>
                    </div>
                </div>
            </template>
            <template v-if="events.type == 'teacher_group_class'">
                <div class="itemImage">
                    <img :src="events.ins_avatar"/>
                    <div class="itemImageInfo">
                        <v-chip small  color="warning" v-if="events.price">{{ events.price }} PHP</v-chip>
                        <v-chip small color="success" v-else>Free</v-chip>
                    </div>
                </div>
            </template>
            <div class="itemDetails">
                <div class="first-row">
                    <div>
                        <div v-if="getEventStatus(events.start).status == 'live'" class="itemStatus">
                            <div class="pill">
                                <v-icon :size="14" color="white">mdi-video</v-icon> Live Now
                            </div>
                        </div>
                        <div v-else-if="getEventStatus(events.start).status == 'not_live_mins'" class="itemStatus">
                            <div class="pill not-live-mins">
                                <v-icon :size="14" color="white">mdi-video</v-icon> Live In {{ getEventStatus(events.start).data }} Min{{ getEventStatus(events.start).data > 0 ? 's' :'' }}
                            </div>
                        </div>
                        <div v-else-if="getEventStatus(events.start).status == 'not_live'" class="itemStatus">
                            <div class="pill not-live">
                                {{ getEventStatus(events.start).data }} Live Class
                            </div>
                        </div>
                        <div v-else-if="getEventStatus(events.start).status == 'waiting'" class="itemStatus">
                            <div class="pill waiting">
                                Class About To Start
                            </div>
                        </div>
                    </div>
                    <div>
                        <v-chip v-if="events.reservation_status == 'paid'"  color="success" outlined style="font-size: 12px">
                            <v-icon small left>mdi-check-decagram</v-icon> Reserved
                        </v-chip>
                    </div>
                </div>
                
                <div class="itemText">
                    <span class="itemSub">{{ events.cat_name }} - {{ events.sub_name }}  </span>
                    <h4 class="truncate">{{ events.name }}</h4>
                    <span class="itemSub">{{ events.instructor }}</span>
                </div>
            </div>
           
            
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    name: 'DawataAppCategory', 
    props: ['events','isfree'],
    data() {
        return {
            componentEvents: [],
            timezone:null,
            today:null
        };
    },
    watch: {
        events(newEvents) {
            console.log("TYYYY", newEvents);
            this.componentEvents = newEvents;
        }
    },
    mounted() {
        // Any code that should run when the component is mounted
        this.timezone = moment.tz.guess();
        this.today = moment().tz(this.timezone);
    },
    methods: {
        getEventStatus(start) {
            const now = moment().tz(this.timezone);
            const startTime = moment(start).tz(this.timezone);
            const diffMinutes = startTime.diff(now, 'minutes');
            const diffHours = startTime.diff(now, 'hours');

            if (diffMinutes <= 0) {
                return {status:'live'};
            } else if (diffMinutes <= 10) {
                return {status:'waiting'};
            } else if (diffHours < 1) {
                return {status:'not_live_mins', data: diffMinutes};
            } else {
                return {status:'not_live', data: startTime.format('hh:mm A')};
            }
        },
        openEvent(event){
            this.$emit('goToLive', event)
        }
        // Define any methods you need here
    },
};
</script>
<style lang="scss" scoped>
    @import './liveRenderItem.scss';
</style>